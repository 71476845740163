<div class="product-dialog">

  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
        <div fxFlex="100" fxFlex.gt-sm="50">
            <div class="swiper-container h-100">
                <div class="swiper-wrapper">
                  <img [src]="product.pictures[0].big" alt="" class="img-fluid">
                </div>

            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">
          <div class="product-right">
              <div class="product-details">
                  <h2 class="mb-10">{{product.name}}</h2>
                <h4 class="mb-10" ><del>{{product.salePrice | currency:productsService?.currency:'symbol'}}</del><span>{{product.discount}}% off</span></h4>
                <h3 class="price mb-10">{{product.price | currency:productsService?.currency:'symbol' }}</h3>
                <div class="discription">
                  <p class="bold">Product details</p>
                  <p class="text-muted productDescription">{{product.description}}</p>
                </div>
            </div>
            <div class="py-1 mt-15">
                <p>
                  <span class="text-muted fw-500">Avalibility: </span>
                <span class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></span>
                <span class="avalibility" *ngIf="counter > product.stock"><span class="red">Out of Stock</span></span>
              </p>
            </div>
            <div class="quantity mt-15">
                <span class="text-muted fw-500">Quantity</span>
                <div fxLayout="row" fxLayout.xs="column"  class="text-muted mt-15">
                  <div fxLayout="row" fxLayoutAlign="center start">
                      <button mat-icon-button matTooltip="Remove" (click)="decrement()"><mat-icon>remove</mat-icon></button>
                      <input type="text" name="quantity" value="{{counter}}" class="form-control input-number">
                      <button mat-icon-button matTooltip="Add" (click)="increment()"><mat-icon>add</mat-icon></button>
                  </div>
              </div>
            </div>
            <div class="buttons mt-20">
                <button mat-raised-button color="primary" class="btn-project mr-10" (click)="addToCart(product, counter)">ADD TO CARD</button>
                <button mat-raised-button color="primary" class="btn-project" (click)="buyNow()">VIEW DETAIL</button>
            </div>
          </div>



        </div>
    </div>
  </div>

  </div>
