    <!-- Load Conent -->
    <div class="container-xl  productgroepen">
    
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Webshop</li>
            </ol>
        </nav>

        <div class="row" [hidden]="list">
            <div class="col-10 text-center"><h1 style="margin-left:22%"class="mb-5 text-redson">Productgroepen</h1></div>
            <div class="col-2 text-right">
                <button [hidden]="list" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeList()"><fa-icon [icon]="faList"></fa-icon></button> 
                <button [hidden]="grid" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeGrid()"><fa-icon [icon]="faTh"></fa-icon></button>
            </div>
    
                <div class="col-xs-4 col-md-5 col-lg-3" *ngFor="let category of categories">
        
                    <a [routerLink]="['/webshop/', category.iGroupId]" class="card mb-3 border-0 productgroep">

                        <div class="card-body py-1">

                            <!-- <div class="shadow" style="width:100%;height:225px;background-image:url(../assets/img/boor.jpg);background-repeat:no-repeat;background-size:200px;background-position: center center;">
                            </div> -->
                            <img class="shadow" style="width:200px;height:200px;object-fit: contain;padding: 20%;" src="{{shopimagesmedium}}{{category.sGroupImg}}" onerror="this.src='../assets/img/default-img.png';">

                            <p class="card-text text-center mt-2">      {{ category.sGroupDesc }}</p>

                        </div>
                    </a>
                </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <ul class="list-group" *ngFor="let category of categories">
                    <li [routerLink]="['/webshop/category/', category.iGroupId]" class="list-group-item"><img style="width:100%;height:55px;background-repeat:no-repeat;background-size:100px;background-position: center center;padding: 20%;" src="{{shopimagesmedium}}{{category.sGroupImg}}">
                        {{ category.sGroupDesc }}</li>
                </ul>
           </div>
        </div> -->
        <div class="row" [hidden]="grid">
            <div class="col-10 text-center"><h1 style="margin-left:22%"class="mb-5 text-redson">Productgroepen</h1></div>
            <div class="col-2 text-right">
                <button [hidden]="list" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeList()"><fa-icon [icon]="faList"></fa-icon> </button> 
                <button [hidden]="grid" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeGrid()"><fa-icon [icon]="faTh"></fa-icon> </button>
            </div>
            <div class="col-lg-12 mx-auto">
                <!-- List group-->
                <ul class="list-group shadow">
                    <!-- list group item-->
                    <li class="list-group-item" *ngFor="let category of categories" [routerLink]="['/webshop/', category.iGroupId]">
                        <!-- Custom content-->
                        <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                            <img src="{{shopimagesmedium}}{{category.sGroupImg}}" onerror="this.src='../assets/img/default-img.png';" alt="Generic placeholder image" width="100" class="order-2 order-lg-1">

                            <div class="ml-lg-5 order-3 order-lg-1">
                                <h5 class="card-text font-weight-bold mt-2">{{ category.sGroupDesc }}</h5>
                                <div class="d-flex align-items-center justify-content-between mt-1">
                                    <!-- <h6 class="font-weight-bold my-2">₹64,999</h6> -->
                                    <ul class="list-inline small">
                                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                        <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div> <!-- End -->
                    </li> <!-- End -->
                </ul> <!-- End -->
            </div>
        </div>
    </div>
    <!--End -->
<!-- <div class="left-sidebar-wrapper sec-padding">
<div class="container">
  <div class="sidebar-wrapper" fxLayout='row' fxLayout.lt-md="column" >
      <div fxFlex="25" fxFlex.lt-sm="100" class="filter-sidenav" perfectScrollbar>
          <div class="toggles-wrapper" fxLayout="column" fxLayout.lt-md="column">
              <div class="toggles" fxFlex.lt-md="100">
                  <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <span>Categories</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                    <app-categories></app-categories>
                      </mat-expansion-panel>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span>Brands</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <div class="brands">
                             <app-brands (brandChanged)="onBrendsChanged($event)"></app-brands>
                           </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <span>Price</span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>

                            <app-price (priceFilters)="updatePriceFilters($event)"></app-price>
                          </mat-expansion-panel>
                    </mat-accordion>
              </div>
                  <div class="popular-products" fxFlex.lt-md="100">
                    <app-popular-products></app-popular-products>
                  </div>
          </div>

          </div>
        <div fxFlex="75" fxFlex.lt-sm="100" class="all-products" ngClass.gt-sm="p-left">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxHide.lt-md="true" class="filter-row mat-elevation-z1 text-muted">
                <button *ngIf="!sidenavOpen" mat-icon-button (click)="sidenav.toggle()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <div >
                    <select (change)="onChangeSorting($event.target.value)">
                        <option value="asc">Sorting items</option>
                        <option value="asc">Ascending Order</option>
                        <option value="desc">Descending Order</option>
                        <option value="a-z">Alphabetically, A-Z</option>
                        <option value="z-a">Alphabetically, Z-A</option>
                        <option value="low">Price, low to high</option>
                        <option value="high">Price, high to low</option>
                      </select>

                </div>
                <div>

                    <button mat-icon-button (click)="changeViewType('list', 100)">
                        <mat-icon>view_list</mat-icon>
                    </button>
                    <button mat-icon-button (click)="changeViewType('list', 33.3)">
                        <mat-icon>view_module</mat-icon>
                    </button>
                    <button mat-icon-button (click)="changeViewType('list', 25)">
                        <mat-icon>view_comfy</mat-icon>
                    </button>
                </div>
              </div>
              <div [@Animation]="animation" fxLayout="row wrap" class="products-wrapper">
                  <div *ngFor="let products of allItems | orderBy:sortByOrder | paginate: { itemsPerPage: 12, currentPage: page  }" fxFlex="100" [fxFlex.gt-sm]="viewCol" fxFlex.sm="50" class="col">
                     <div class="product-box">
                        <app-product [product]="products"></app-product>
                     </div>
                  </div>
              </div>

              <div *ngIf="products.length > 0" fxLayout="row wrap ">
                  <div fxFlex="100">
                      <mat-card class="p-0 text-center no-shadow">
                          <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
                      </mat-card>
                  </div>
              </div>
            </div>
  </div>
</div>
</div> -->
