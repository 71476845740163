<div class="product-dialog">

  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
        <div>
            <div class="swiper-container h-100">
                <div class="swiper-wrapper">
                  <img [src]="product.pictures[selectedProductImageIndex].big" alt="" class="img-fluid">
                </div>

            </div>
        </div>

    </div>
  </div>

  </div>
