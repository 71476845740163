        <!-- Load Footer -->
        <footer id="footer" class="container-xl">
        <div class="container-fluid" style="padding-left:10%;padding-right:10%">
          <div class="row">

              <div class="col-lg-3 col-md">
                  <h5>Blue Rebel Interior</h5>

                  <ul style="cursor:pointer" class="list-unstyled text-small">
                      <a style="color:white" href="https://www.google.ca/maps/place/Blue+Rebel+BV/@52.0368665,4.8631031,17z/data=!3m1!4b1!4m5!3m4!1s0x47c67969104e6daf:0x7a95f8b11826d50b!8m2!3d52.0368665!4d4.8652918"><li><img src="assets/img/Unknown-2.png" style="width:14px;margin-bottom: 10px;" class="img-fluid" alt="Insta"> Elzenweg 19</li></a>
                      <a style="color:white" href="https://www.google.ca/maps/place/Blue+Rebel+BV/@52.0368665,4.8631031,17z/data=!3m1!4b1!4m5!3m4!1s0x47c67969104e6daf:0x7a95f8b11826d50b!8m2!3d52.0368665!4d4.8652918"><li>3421 TT Oudewater</li></a>
                      <a style="color:white" href="https://www.google.ca/maps/place/Blue+Rebel+BV/@52.0368665,4.8631031,17z/data=!3m1!4b1!4m5!3m4!1s0x47c67969104e6daf:0x7a95f8b11826d50b!8m2!3d52.0368665!4d4.8652918"><li>The Netherlands</li></a>
                  </ul>
              </div>
              <div class="col-lg-3 col-md">
                <ul class="list-unstyled text-small">
                  <li>Opening hours:</li>
                  <li>Monday - Friday</li>
                  <li>8.30 - 17.00 h.</li>
                </ul>
            </div>

              <div class="col-lg-3 col-md">
                  <h5 style="font-size: 1rem;"><a href="tel:+31(0)348203100" style="color:white;">+31(0)348 203100</a></h5>
                  <h5 style="font-size: 1rem;"><a href="tel:+31(0)620566432" style="color:white;">+31(0)6 20566432</a></h5>

                  <ul class="list-unstyled text-small">
                    <li><a href="mailto:info@bluerebelinterior.nl" style="color:white;">info@bluerebelinterior.nl</a></li>
                  </ul>

              </div>
              <div class="col-lg-3 col-md">
                <ul [routerLink]="['/pages/contact']" style="cursor: pointer;"class="list-unstyled text-small">
                  <li>World wide shipping</li>
                  <li>Pick-up service airport</li>
                  <li>Make an appointment ></li>
                </ul>

            </div>
            <!-- <div class="col-lg-2 col-md">
              <ul style="cursor: pointer;"class="list-unstyled text-small">
                <li [routerLink]="['/pages/collection/furniture']">Furniture ></li>
                <li [routerLink]="['/pages/collection/decorations']">Decorations ></li>
                <li [routerLink]="['/pages/collection/architecturals']">Architecturals ></li>
                <li [routerLink]="['/pages/collection/textiles']">Textiles ></li>
              </ul>

          </div>
          <div class="col-lg-2 col-md">
            <ul style="cursor: pointer;"class="list-unstyled text-small">
              <li [routerLink]="['/pages/gallery']">Gallery ></li>
              <li [routerLink]="['/pages/about']">About us ></li>
              <li [routerLink]="['/pages/contact']">Contact ></li>
            </ul>

        </div> -->
              <!-- <div class="col-lg-2 col-md">
                <h5><a href="https://www.instagram.com/bluerebel.interior/" style="color:white;"><img src="assets/img/insta.png" style="width:54px;" class="img-fluid" alt="Insta"></a></h5>

                <ul class="list-unstyled text-small">
                    <li><a href="https://www.instagram.com/bluerebel.interior/" style="color:white;">Follow us on instagram</a></li>
                </ul>
              </div> -->
              <!-- <div class="col-lg-2 col-md"> -->
                <!-- <iframe width="260" height="240" src="https://www.instagram.com/p/CVc-wx8MABn/embed/" frameborder="0"></iframe> -->
                <!-- <div class="embedsocial-hashtag" data-ref="2021e5d95a8d55c9c1b5a95125969ee1b69d1f81"></div> -->
              <!-- </div> -->
          </div>
      
          <!-- <div class="row" style="margin-top:30px">
            <div class="col-lg-12 col-md"> -->
              <!-- <iframe width="260" height="240" src="https://www.instagram.com/p/CVc-wx8MABn/embed/" frameborder="0"></iframe> -->
              <!-- <div class="embedsocial-hashtag" data-ref="2021e5d95a8d55c9c1b5a95125969ee1b69d1f81"></div>
            </div>
        </div> -->

          <p></p>

  

     
          </div>
      </footer>
      <footer style="background-color: #2f3e46;">
        <div style="padding-left: 10%;
        padding-right: 10px;
        background-color: #2f3e46;
        padding-bottom: 20px;" class="container-fluid">
        <p style="color:white">General terms & conditions
          Privacy & cookiepolicy
          Disclaimer
          CoC
          Copyright 2021</p>
        </div>
      </footer>