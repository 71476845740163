    <!-- Load Conent -->
    <div class="container-xl  order">



      <div class="row">
          <div class="col-12 text-left">
              <h1 class="mb-5 text-redson">Afrekenen</h1>
          </div>

          <div class="col-md-12">
              <form [formGroup]="form" (ngSubmit)="onPlaceOrder()" >

                  <div class="row">
                      <fieldset class="col">
                          <legend>Gegevens</legend>
                          <div class="form-group">
                              <label for="Voornaam">Naam <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" disabled="true" formControlName="name" name="Voornaam" value="" size="25" maxlength="80" placeholder="" id="Voornaam">
                          </div>
                          <!-- <div class="form-group">
                              <label for="Achternaam">Achternaam <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" name="Achternaam" value="" size="25" maxlength="80" placeholder="" id="Achternaam">
                          </div> -->
                          <div class=" form-group">
                              <label for="Straatnaam">Straatnaam + Huisnummer <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" disabled="true" formControlName="street" name="Straatnaam" value="" size="25" maxlength="80" placeholder="" id="Straatnaam">
                          </div>
                          <!-- <div class=" form-group">
                              <label for="Huisnummer">Huisnummer <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" formControlName="housenumber" name="Huisnummer" value="" size="25" maxlength="80" placeholder="" id="Huisnummer">
                          </div> -->
                          <div class=" form-group">
                              <label for="Postcode">Postcode <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" disabled="true" formControlName="zipcode" name="Postcode" value="" size="25" maxlength="80" placeholder="" id="fPostcode">
                          </div>
                          <div class="required form-group">
                              <label for="Woonplaats">Woonplaats <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" disabled="true" formControlName="city" name="m5d43bfbrp__37" value="" size="25" maxlength="80" placeholder="" required="" id="Woonplaats">
                          </div>
                          <div class="required form-group">
                              <label for="E-mailadres">E-mailadres <span class="required_symbol">*</span></label>
                              <input class="form-control" type="email" disabled="true" formControlName="email" name="E-mailadres" value="" size="25" maxlength="128" placeholder="" required="" id="E-mailadres">
                          </div>
                          <div class="required form-group">
                              <label for="Telefoonnummer">Telefoonnummer <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" disabled="true" formControlName="telephone" name="Telefoonnummer" value="" size="25" maxlength="80" placeholder="" required="" id="Telefoonnummer">
                          </div>
                          <!-- <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="account_aanmaken">
                              <label class="form-check-label" for="account_aanmaken">
                                  Een account aanmaken
                              </label>
                          </div> -->
                      </fieldset>
                      <fieldset class="col">
                          <legend>Bedrijfsgegevens</legend>
                          <div class="form-group">
                              <label for="Bedrijfsnaam">Bedrijfsnaam (optioneel)</label>
                              <input class="form-control" type="text" disabled="true" formControlName="company" name="Bedrijfsnaam" value="" size="25" maxlength="80" placeholder="" id="Bedrijfsnaam">
                          </div>

                          <div class="form-group">
                              <label for="BTW">BTW-NR (optioneel)</label>
                              <input class="form-control" type="text" disabled="true" formControlName="btw" name="BTW" value="" size="25" maxlength="80" placeholder="" id="BTW">
                          </div>

                          <div class="form-group">
                              <label for="KVK">KVK (optioneel)</label>
                              <input class="form-control" type="text" disabled="true" formControlName="kvk" name="KVK" value="" size="25" maxlength="80" placeholder="" id="KVK">
                          </div>

                      </fieldset>
                  </div>



                  <div class="row mt-5">
                      <!-- <fieldset class="col-12 mb-5">
                          <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="verzenden_ander_adres">
                              <label class="form-check-label" for="verzenden_ander_adres">
                                  Verzenden naar een ander adres
                              </label>
                          </div>
                      </fieldset> -->
                      <fieldset class="col" disabled>
                          <legend>Factuur gegevens</legend>
                          <div class="form-group">
                              <label for="Voornaam">Naam<span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" formControlName="name" disabled="true" name="Voornaam" value="" size="25" maxlength="80" placeholder="" id="Voornaam">
                          </div>
                          <div class="form-group">
                              <label for="Bedrijfsnaam">Bedrijfsnaam (optioneel)</label>
                              <input class="form-control" type="text" formControlName="company" disabled="true" name="Bedrijfsnaam" value="" size="25" maxlength="80" placeholder="" id="Bedrijfsnaam">
                          </div>
                          <div class=" form-group">
                              <label for="Straatnaam">Straatnaam <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" formControlName="streetinvoice" disabled="true" name="Straatnaam" value="" size="25" maxlength="80" placeholder="" id="Straatnaam">
                          </div>
                          <div class=" form-group">
                              <label for="Huisnummer">Huisnummer <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" formControlName="housenumberinvoice" disabled="true" name="Huisnummer" value="" size="25" maxlength="80" placeholder="" id="Huisnummer">
                          </div>
                          <div class=" form-group">
                              <label for="Postcode">Postcode <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" formControlName="zipcodeinvoice" disabled="true" name="Postcode" value="" size="25" maxlength="80" placeholder="" id="fPostcode">
                          </div>
                          <div class="required form-group">
                              <label for="Woonplaats">Woonplaats <span class="required_symbol">*</span></label>
                              <input class="form-control" type="text" formControlName="cityinvoice" disabled="true" name="m5d43bfbrp__37" value="" size="25" maxlength="80" placeholder="" required="" id="Woonplaats">
                          </div>
                      </fieldset>
                      <fieldset class="col-12 my-5">
                          <legend>Opmerkingen</legend>
                          <div class=" form-group">
                              <label for="Opmerking">Bestelnotities (optioneel)</label>
                              <textarea class="form-control" formControlName="notice" name="Opmerking" id="Opmerking" cols="80" rows="5" placeholder="Notities over je bestelling, bijvoorbeeld speciale notities voor aflevering."></textarea>
                          </div>
                      </fieldset>
                  </div>

              </form>
          </div>


          <div class="col-12 text-left">
              <h2 class="mb-5 text-redson">Jouw bestelling</h2>
          </div>


          <div class="col-md-12">



              <table class="table table-bordered">
                  <thead>
                      <tr>
                          <th scope="col">Product</th>
                          <th scope="col">Subtotaal</th>
                      </tr>
                  </thead>

                  <tbody>
                      <tr *ngFor="let item of orderline">
                          <th scope="row">
                              <span class="font-weight-light">{{ item.sDescription1 }}</span> x {{ item.nAmount }}</th>
                          <td><span class="Price-amount amount"><span class="Price-currencySymbol"></span>&nbsp;{{item.curNetPriceInc | currency:'EUR':number:'':'nl':true}}</span></td>
                      </tr>
                      <tr>
                          <th scope="row">Subtotaal</th>
                          <td><span class="Price-amount amount"><span class="Price-currencySymbol"></span>&nbsp;{{orderprice?.curTotIncl | currency:'EUR':number:'':'nl':true}}</span></td>
                      </tr>
                      <!--<tr>
                          <th scope="row"></th>
                          <td>
                              <ul id="shipping_method" class="shipping-methods">
                                  <li>
                                      <input type="radio" name="shipping_method[0]" data-index="0" id="shipping_method_0_free_shipping1" value="free_shipping:1" class="shipping_method" checked="checked"><label for="shipping_method_0_free_shipping1">Gratis verzending</label> </li>
                                  <li>
                                      <input type="radio" name="shipping_method[0]" data-index="0" id="shipping_method_0_local_pickup2" value="local_pickup:2" class="shipping_method"><label for="shipping_method_0_local_pickup2">Afhalen</label> </li>
                              </ul>

                          </td>
                      </tr>-->
                      <tr>
                          <th scope="row">Totaal</th>
                          <td data-title="Totaal"><strong><span class="Price-amount amount"><span class="Price-currencySymbol"></span>&nbsp;{{orderprice?.curTotIncl | currency:'EUR':number:'':'nl':true}}</span></strong></td>
                          <!-- <small class="includes_tax">(inclusief <span class="Price-amount amount"><span class="Price-currencySymbol">€</span>&nbsp;24.30</span> btw)</small> -->
                      </tr>
                  </tbody>
              </table>

          </div>
          <div class="col-md-12 text-center">
              <button (click)="orderfactchange(email)" role="button" class="btn btn-redson btn-lg">Bestellen</button>
          </div>

      </div>



  </div>
  <!--End -->
<!-- <div class="container">
  <div class="checkout sec-padding">
      <div fxLayout="row wrap" class="">
          <div class="billing-details  pr-15" fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50">
            <div class="header-title">
                <h2>Billing Details</h2>
            </div>

              <form fxLayout="row wrap">
                  <div  fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50" class="mt-20 pr-5" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="First name (required)" #name="ngModel" ngModel name="name" required>
                          <mat-error *ngIf="name.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50" class="pl-5 mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Last name (required)" #email="ngModel" ngModel name="email" required>
                          <mat-error *ngIf="email.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-sm="100" class="mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Company name (optional)" #subject="ngModel" ngModel name="subject">
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-sm="100" class="mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Address" #address="ngModel" ngModel name="address">
                          <mat-error *ngIf="address.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-sm="100" class="mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Town/city" #town="ngModel" ngModel name="town/city">
                          <mat-error *ngIf="town.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50" class="mt-20 pr-5" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="State / Country (required)" #state="ngModel" ngModel name="state" required>
                          <mat-error *ngIf="state.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50" class="pl-5 mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Postcode / zip (required)" #postcode="ngModel" ngModel name="postcode" required>
                          <mat-error *ngIf="postcode.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50" class="mt-20 pr-5" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Email Address (required)" #email="ngModel" ngModel name="email" required>
                          <mat-error *ngIf="email.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div  fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50" class="pl-5 mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <input matInput placeholder="Phone (required)" #phone="ngModel" ngModel name="phone" required>
                          <mat-error *ngIf="phone.invalid">Please enter a valid message</mat-error>
                        </mat-form-field>
                  </div>
                  <div class="add-info">
                    <h3>Additional information</h3>
                    <p class="text-muted">Order notes (optional)</p>

                  </div>
                  <div  fxFlex="100" fxFlex.gt-sm="100" class="mt-20" ngClass.sm="mt-10" ngClass.xs="mt-10">
                      <mat-form-field class="w-100">
                          <textarea placeholder="Post content" matInput rows="4" ngModel name="content"></textarea>
                        </mat-form-field>
                  </div>
                  <div class="button-wrap" fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                      <button class="btn-project" mat-raised-button color="accent" type="submit">Submit Review</button>
                    </div>
                  </form>
          </div>
          <div class="mainOrder-wrapper pl-15" fxFlex="100" fxFlex.gt-md="50" fxFlex.md="50">
            <div class="main-order " >
              <div class="order-box">
                  <div class="title-box">
                      <div>Product <span class="tl-amount"> Total</span></div>
                  </div>
                  <ul class="price-list" *ngIf='buyProducts.length'>
                    <li *ngFor="let item of buyProducts">
                       {{ item.product.name }} × {{ item.quantity }}
                      <span>
                       {{ item.product.price * item.quantity | currency:productService?.currency:'symbol' }}
                        </span>
                    </li>
                </ul>
                <ul class="quantity" *ngIf='buyProducts.length <= 0'>
                  <li class="empty-checkout"> There are no products in cart </li>
                 </ul>
                <ul class="total-amount">
                  <li>
                    Subtotal <span class="amount">{{ getTotal() | async | currency:productService?.currency:'symbol'}}</span>
                  </li>
                  <li *ngIf='buyProducts.length'>
                    Shipping
                   <div class="shipping">
                      <div class="shopping-option">
                        <mat-radio-group>
                          <mat-radio-button *ngFor="let payment of payments" [value]="payment">{{payment}}</mat-radio-button>
                        </mat-radio-group>
                      </div>

                   </div>
                  </li>
              </ul>
              <ul class="total">
                <li>Total <span class="count">{{ getTotal() | async | currency:productService?.currency:'symbol'}}</span></li>
            </ul>
            <div class="paymentFinal">
              <mat-radio-group>
                <mat-radio-button fxLayout="column" *ngFor="let payment of paymantWay" [value]="payment">{{payment}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="order">
              <button class="btn-project" mat-raised-button color="accent" type="submit">PLACE ORDER</button>
            </div>
               </div>
          </div>
          </div>

       </div>
    </div>
</div> -->
