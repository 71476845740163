<div class="container-xl producten">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Webshop</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{group?.sGroupDesc}}</li>
        </ol>
    </nav>

    <div class="row" [hidden]="list">
        <div class="col-10 text-center"><h1 style="margin-left:22%"class="mb-5 text-redson">{{group?.sGroupDesc}}</h1></div>
        <div class="col-2 text-right">
            <button [hidden]="list" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeList()"><fa-icon [icon]="faList"></fa-icon> </button> 
            <button [hidden]="grid" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeGrid()"><fa-icon [icon]="faTh"></fa-icon> </button>
        </div>
        <div style="cursor: pointer;" class="col-md-7 col-lg-4" *ngFor="let item of items" [routerLink]="['/product', item.sArticleCode]">
            <div class="card rounded-0 border-0 mb-3 pt-2 product" style="height: 400px;">
                <div class="card-header bg-transparent border-0 p-0 pt-2 text-center text-dark">
                    <h5 class="card-title font-weight-light" style="background: white;">{{ item?.sDescription1 }} <span class="article_numberholder">520204</span></h5>
                    <p *ngIf="authService.checkPriceTypeNettoPriceIncl()" class="card-text font-weight-bold text-redson">{{item.stPrice.curNetPriceInc | currency:'EUR':number:'':'nl':true}} <small class="text-dark">INCL. BTW</small></p>
                    <p *ngIf="authService.checkPriceTypeNettoPriceExcl()" class="card-text font-weight-bold text-redson">{{item.stPrice.curNetPrice | currency:'EUR':number:'':'nl':true}} <small class="text-dark">EXCL. BTW</small></p>
                    <p *ngIf="authService.checkPriceTypeGrossPrice()" class="card-text font-weight-bold text-redson">{{item.stPrice.curNetPrice | currency:'EUR':number:'':'nl':true}} <small class="text-dark">Bruto Prijs</small></p>
                </div>
                <div class="card-body d-flex align-items-center text-center">

                </div>
                <div class="card-footer bg-transparent border-0 text-center effect-top">
                    <!-- <form name="myform_2331" action="/">
                        <div class="input-group mb-1">
                            <input id="mySelect_2331" class="form-control form-control-sm border-secondary select" type="number" name="customer" value="1">
                            <div class="input-group-append">
                                <input class="btn btn-secondary btn-sm rounded-right" type="button" value="Bestellen">
                            </div>
                        </div>
                    </form> -->
                    <a [routerLink]="['/product', item.sArticleCode]" *ngIf="!item.stVariants.stVariantArticles.arrVariantsArticles.length" class="btn btn-outline-secondary btn-block btn-sm">Bekijk dit product</a>
                    <a class="btn btn-outline-secondary btn-block btn-sm" *ngIf="item.stVariants.stVariantArticles.arrVariantsArticles.length" [routerLink]="['/product/variant', category]">Verkrijgbaar in {{item.stVariants.stVariantArticles.arrVariantsArticles.length}} varianten</a>

                </div>
                <img class="img-zoom w-100 h-100 position-absolute" style="width:200px;height:200px;object-fit: contain;padding: 20%;" src="{{shopimagesmedium + item.sArticleImage}}" onerror="this.src='../assets/img/default-img.png';">
                <!-- <img *ngIf="!item.sArticleImage || item.sArticleImage === ''" class="img-zoom w-100 h-100 position-absolute" src="{{defaultimg}}" style="background-repeat:no-repeat;background-size:200px;background-position: center center;"> -->
            </div>
        </div>
    </div>
    <div class="row" [hidden]="grid">
        <div class="col-10 text-center"><h1 style="margin-left:22%"class="mb-5 text-redson">{{group?.sGroupDesc}}</h1></div>
        <div class="col-2 text-right">
            <button [hidden]="list" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeList()"><fa-icon [icon]="faList"></fa-icon> </button> 
            <button [hidden]="grid" style="border:solid 2px white"class="btn btn-redson" (click)="ChangeGrid()"><fa-icon [icon]="faTh"></fa-icon> </button>
        </div>
        <div class="col-lg-12 mx-auto">
            <!-- List group-->
            <ul class="list-group shadow">
                <!-- list group item-->
                <li class="list-group-item" style="cursor: pointer;" *ngFor="let item of items" [routerLink]="['/product', item.sArticleCode]">
                    <!-- Custom content-->
                    <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                        <img src="{{shopimagesmedium}}{{item.sArticleImage}}" onerror="this.src='../assets/img/default-img.png';" alt="Generic placeholder image" class="order-2 order-lg-1" style="width:200px;height:200px;object-fit: contain;">

                        <div class="ml-lg-5 order-3 order-lg-1">
                            <h5 class="card-text font-weight-bold mt-2">{{ item?.sDescription1 }}</h5>
                            <div class="d-flex align-items-center justify-content-between mt-1">
                                <h6 *ngIf="authService.checkPriceTypeNettoPriceIncl()" class="font-weight-bold my-2">{{item.stPrice.curNetPriceInc | currency:'EUR':number:'':'nl':true}} INCL. BTW</h6>
                                <h6 *ngIf="authService.checkPriceTypeNettoPriceExcl()" class="font-weight-bold my-2">{{item.stPrice.curNetPrice | currency:'EUR':number:'':'nl':true}} EXCL. BTW</h6>
                                <h6 *ngIf="authService.checkPriceTypeGrossPrice()" class="font-weight-bold my-2">{{item.stPrice.curNetPrice | currency:'EUR':number:'':'nl':true}} Bruto Prijs</h6>
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                                </ul>
                            </div>
                            <button class="btn btn-redson" [hidden]="variant" (click)="HideVariant(item.sArticleCode);$event.stopPropagation()" *ngIf="item.stVariants.stVariantArticles.arrVariantsArticles.length">Verkrijgbaar in {{item.stVariants.stVariantArticles.arrVariantsArticles.length}} varianten</button>
                            <button class="btn btn-redson" [hidden]="hiddenvariant" (click)="ShowVariant(item.sArticleCode);$event.stopPropagation()" *ngIf="item.stVariants.stVariantArticles.arrVariantsArticles.length">Verkrijgbaar in {{item.stVariants.stVariantArticles.arrVariantsArticles.length}} varianten</button>
                            <button class="btn btn-redson" *ngIf="!item.stVariants.stVariantArticles.arrVariantsArticles.length" [routerLink]="['/product', item.sArticleCode]">Bekijk dit product</button>
                        </div>
                    </div> <!-- End -->
                        <table class="table" *ngIf="item.stVariants.stVariantArticles.arrVariantsArticles.length && showBtn===item.sArticleCode" [hidden]="variant">
                            <thead>
                            <tr>
                                <th scope="col">Artikelnummer</th>
                                <th scope="col">Artikelomschrijving</th>
                                <th *ngIf="authService.checkPriceTypeNettoPriceIncl() || authService.checkPriceTypeNettoPriceExcl() || authService.checkPriceTypeGrossPrice()" scope="col">Prijs per {{item.sPricePer}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of item.stVariants.stVariantArticles.arrVariantsArticles" (click)="Go(row.sArticleCode);$event.stopPropagation()"  >
                                <th scope="row">{{row.sArticleCode}}</th>
                                <td>{{row.sDescription}}</td>
                                <td *ngIf="authService.checkPriceTypeNettoPriceIncl()">{{row.curNetPriceInc | currency:'EUR':number:'':'nl':true}}</td>
                                <td *ngIf="authService.checkPriceTypeNettoPriceExcl()">{{row.curNetPrice | currency:'EUR':number:'':'nl':true}}</td>
                                <td *ngIf="authService.checkPriceTypeGrossPrice()">{{row.curNetPrice | currency:'EUR':number:'':'nl':true}}</td>
                            </tr>
                            </tbody>
                        </table>
                </li> <!-- End -->
            </ul> <!-- End -->
        </div>
    </div>
</div>


<!--End -->