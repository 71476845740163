import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import * as sha512 from 'sha512';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient) { }

  getMenu(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let content = this.http.get('https://bri.msgrestservices.nl/Content/Menu', { headers: headers });
    console.log('menu', content);
    return content;
  }
  

  getPage(pageid): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let content = this.http.get('https://bri.msgrestservices.nl/Content/Menu/'+pageid, { headers: headers });
    console.log('page', content);
    return content;
  }

  getBlock(pageid): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let content = this.http.get('https://bri.msgrestservices.nl/Content/Menu/PageBlock/'+pageid, { headers: headers });
    console.log('page', content);
    return content;
  }

  getGallery(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let content = this.http.get('https://bri.msgrestservices.nl/App/BRI', { headers: headers });
    console.log('page', content);
    return content;
  }

  // async mail(mailing, item) {
    
  //   let sha = CryptoJS.SHA512('Send a new mail to '+`${mailing}`+'.').toString(CryptoJS.enc.Base64);
  //   // let encode = CryptoJS.enc.Base64.stringify(sha)
  //   let encodedSource1 = sha.replace(/=+$/, '');
  //   let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //   let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //   let part = encodedSource3.slice(4, 25);
  //   console.log('hash', sha);
  //   console.log('hash', encodedSource3);
  //   let string = JSON.stringify(window.localStorage.getItem('Authtoken'))

  //   let headers = new HttpHeaders();
  //   headers.append('Access-Control-Allow-Origin', '*');
  //   headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //   headers.append('Accept', 'application/json');
  //   headers.append('content-type', 'application/json');
  //   console.log(headers);
  //   console.log(string)
  //   console.log('pbmlogin', window.localStorage.getItem('Authtoken'))
  //   console.log(part)
  //   let mail = this.http.post('https://bri.msgrestservices.nl/Mail/'+part, item,{ headers: headers });
  //   console.log(mail)
  //   return mail
  // }


  async mail(mailing, item) {
    console.log(mailing)
    let mail = 'info@bluerebelinterior.nl'
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Request-Headers': '*',
      }
      let oke = 'Send a new mail to '+`${mail}`+'.';
      console.log(oke);
      let sha = CryptoJS.SHA512(oke).toString(CryptoJS.enc.Base64);
      var hash = CryptoJS.HmacSHA512("Contact Aanvraag", oke).toString(CryptoJS.enc.Base64);
      console.log('mail hash', hash)
      let encode = CryptoJS.SHA512(oke)
      console.log(encode)
      let encodedSource1 = hash.replace(/=+$/, '');
      let encodedSource2 = encodedSource1.replace(/\+/g, '-');
      let encodedSource3 = encodedSource2.replace(/\//g, '_');
      let part = encodedSource3.slice(4, 25);
      console.log('hash', sha);
      console.log('hash', encodedSource3);
      let string = JSON.stringify(window.localStorage.getItem('Authtoken'))
      let user = this.http.post('https://bri.msgrestservices.nl/Mail/'+part, item, {'headers':headers})
        .subscribe(data => {
          if (data) {
            console.log(data)
            resolve(true);
          }
          else
            resolve(false);
        });
    });
  }
}
