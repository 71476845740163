import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';
import { ProductCategoryComponent } from './products/product-category/product-category.component';
import { ProductSubcategoryComponent } from './products/product-subcategory/product-subcategory.component';
import { ThanksComponent } from '../../components/pages/thanks/thanks.component';
import { ProductVariantComponent } from './products/product-variant/product-variant.component';


// Routes
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'webshop/:category', component: ProductCategoryComponent },
  { path: 'webshop/category/:subcategory', component: ProductSubcategoryComponent },
  { path: 'webshop/search/:search', component: ProductCategoryComponent },
  { path: 'product/:id', component: ProductDetailsComponent },
  { path: 'product/variant/:id', component: ProductVariantComponent },
  { path: 'thanks', component: ThanksComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
