     <!-- Load Conent -->
     <div class="container-xl  cart">

      <div class="row">
          <div class="col-12 text-left">
              <h1 class="mb-5 text-redson">Winkelmand</h1>
          </div>

          <div class="col-md-12">

              <table id="myTable" class="table table-striped">
                  <thead>
                      <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">Product</th>
                          <th scope="col">Prijs</th>
                          <th scope="col">Aantal</th>
                          <th scope="col">Subtotaal</th>
                      </tr>
                  </thead>
                  <tbody *ngFor="let item of orderline">
                      <tr>
                          <td>
                              <button (click)="removeItem(item, item.iLineID)" class="btn btn-link text-danger"><svg class="bi bi-x-circle-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-4.146-3.146a.5.5 0 00-.708-.708L8 7.293 4.854 4.146a.5.5 0 10-.708.708L7.293 8l-3.147 3.146a.5.5 0 00.708.708L8 8.707l3.146 3.147a.5.5 0 00.708-.708L8.707 8l3.147-3.146z" clip-rule="evenodd"/>
  </svg></button>
                          </td>
                          <td>
                              <img src="../../img/boor.jpg" width="50" alt="">
                          </td>
                          <td>{{ item.sDescription1 }}</td>
                          <td>{{item.curNetPriceInc | currency:'EUR':number:'':'nl':true}}</td>

                          <td>
                            <div class="input-group">
                                <span class="input-group-btn">
                                    <button type="button" (click)="losesMinFocus($event, item)" class="btn btn-secondary btn-number" data-type="minus" data-field="quant[1]">
                                        -
                                    </button>
                                </span>
                                <input type="number" name="customer" class="form-control input-number" (change)="losesFocus($event, item)" value="{{ item.nAmount }}">
                                <span class="input-group-btn">
                                    <button type="button" (click)="losesFocus($event, item)" class="btn btn-secondary btn-number" data-type="plus" data-field="quant[1]">
                                        +
                                    </button>
                                </span>
                            </div>
                              <!-- <input class="form-control select" type="number" name="customer" value="{{ item.nAmount }}"> -->
                          </td>
                          <td>{{item.curNetPriceInc | currency:'EUR':number:'':'nl':true}}</td>

                      </tr>
                  </tbody>
                  <!-- <tfoot>
                      <tr>
                          <td colspan="6" class="text-right">
                              <button type="button" class="btn btn-secondary btn-lg">Winkelmand bijwerken</button>
                          </td>
                      </tr>
                  </tfoot> -->
              </table>

          </div>
          <div class="col-12 text-left">
              <h2 class="mb-5 text-redson">Winkelmand-totalen</h2>
          </div>


          <div class="col-md-12">



              <table class="table table-bordered">

                  <tbody>
                      <tr>
                          <th scope="row">Totaal</th>
                          <td><span class=" amount"><span class="Price-currencySymbol"></span>&nbsp;{{orderprice?.curTotIncl | currency:'EUR':number:'':'nl':true}}</span></td>
                      </tr>
                      
                      <!-- <tr>
                          <th scope="row">Totaal</th>
                          <td data-title="Totaal"><strong><span class="Price-amount amount"><span class="Price-currencySymbol">€</span>&nbsp;140.00</span></strong> <small class="includes_tax">(inclusief <span class="Price-amount amount"><span class="Price-currencySymbol">€</span>&nbsp;24.30</span> btw)</small></td>
                      </tr> -->
                  </tbody>
              </table>

          </div>
          <div class="col-md-12 text-center">
              <a [routerLink]="['/checkout']" role="button" class="btn btn-redson btn-lg">Doorgaan naar bestellen</a>
          </div>

      </div>

  </div>
  <!--End -->

<!-- <div class="container">
  <div class="cart-wrapper sec-padding">
     <div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="!shoppingCartItems.length">
        <mat-chip-list>
           <mat-chip color="warn" selected="true">YOU HAVE NO ITEMS IN YOUR SHOPPING CART.</mat-chip>
        </mat-chip-list>
        <div>
           <a  mat-raised-button color="primary" class="btn-project mt-20">Continue shopping</a>
        </div>
     </div>
     <mat-card *ngIf="shoppingCartItems.length"  class="p-0">
        <div class="mat-table cart-table">
           <div class="mat-header-row">
              <div class="mat-header-cell">Product</div>
              <div class="mat-header-cell">Name</div>
              <div class="mat-header-cell">Price</div>
              <div class="mat-header-cell">Quantity</div>
              <div class="mat-header-cell">Total</div>
              <div class="mat-header-cell">Action</div>
           </div>
           <div *ngFor="let item of shoppingCartItems"  class="mat-row">
              <div class="mat-cell"><img [src]="item.product.pictures[0].small"></div>
              <div class="mat-cell"><a  class="product-name">{{item.product.name}}</a></div>
              <div class="mat-cell price">{{item.product.price | number : '1.2-2'}}</div>
              <div class="mat-cell text-muted">
                 <div fxLayout="row" fxLayout.xs="column"  class="text-muted">
                    <div fxLayout="row" fxLayoutAlign="center start">
                       <button mat-icon-button matTooltip="Remove" (click)="decrement(item.product)">
                          <mat-icon>remove</mat-icon>
                       </button>
                       <input type="text" name="quantity"  [(ngModel)]="item.quantity" disabled class="form-control input-number">
                       <button mat-icon-button matTooltip="Add" (click)="increment(item.product)">
                          <mat-icon>add</mat-icon>
                       </button>
                    </div> -->
                    <!-- <div *ngIf="type!='wish'">
                       <button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button>
                       <button mat-icon-button matTooltip="Add to cart" *ngIf="product?.availibilityCount > 0" (click)="addToCart(product)"><mat-icon>shopping_cart</mat-icon></button>
                       <button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>
                       <button *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button>
                       </div> -->
                 <!-- </div>
              </div>
              <div class="mat-cell total">{{item.product.price * item.quantity | number : '1.2-2' }}</div>
              <div class="mat-cell text-center">
                 <div class="p-1">
                    <mat-icon (click)="removeItem(item)">close</mat-icon>
                 </div>
              </div>
           </div>
           <div class="mat-row">
              <div class="mat-cell"><a  mat-raised-button  class="btn-project" [routerLink]="['/home/one']" routerLinkActive="router-link-active" >Continue shopping</a></div>
              <div class="mat-cell"></div>
              <div class="mat-cell"></div>
              <div class="mat-cell text-right">
                 <div fxLayout="column" fxLayoutAlign="center end" class="grand-total px-2">
                    <span class="new-price">Grand total: {{getTotal()  | async | currency:productsService?.currency:'symbol'}}</span>
                 </div>
              </div>
              <div class="mat-cell text-center"><a mat-raised-button  class="btn-project" [routerLink]="['/pages/checkout']" routerLinkActive="router-link-active" >Proceed To Checkout</a></div>
           </div>
        </div>
     </mat-card>
  </div>
</div> -->
