<!-- <app-main-carousel [slides]="slides"></app-main-carousel> -->

<div class="home">
   <app-menu></app-menu>
   

<div class="owl-carousel owl-theme row owl-dot-inner owl-dot-white intro-slider animation-slider cols-1 gutter-no" style="margin-left:0px !important">
    <div class="banner banner-fixed video-banner intro-slide3">
       <figure class="d-none d-lg-block d-md-block d-sm-block display-1"><img alt="intro-banner" src="https://msgcms.msgsoftware.nl/MsgCms_web/BRI/ShopImages/BR_pc_banner01_A_home.jpg" width="1903" height="630"></figure>
       <figure class="d-block d-sm-none display-1"><img alt="intro-banner" src="https://msgcms.msgsoftware.nl/MsgCms_web/BRI/ShopImages/BR_mob_banner01_A_home.jpg" width="1903" height="630"></figure>
       <div class="container">
        <div class="banner-content x-50 y-50 text-center">
            <!-- <h2 class="banner-title mb-3 text-white font-weight-bold text-uppercase ls-m slide-animate" data-animation-options="{'name': 'fadeInUp', 'duration': '.7s', 'delay': '.5s'}"> De motor achter je bedrijfsvoering</h2>
            <a class="btn btn-danger btn-rounded btn-lg slide-animate mb-1" data-animation-options="{'name': 'fadeInLeft', 'duration': '1s', 'delay': '1.5s'}" href="shop.html" style="color:white !important">oke<i _ngcontent-ebu-c1="" class="d-icon-arrow-right"></i></a> -->
            <div class="text-center">
              <picture>
                  <source srcset="assets/img/eye.png" type="image/svg+xml" />
                  <img src="assets/img/BR_oog.png" style="height: 100%;" class="img-fluid eye" alt="..." />
              </picture>
          </div>
          <div  class="banner-title mb-3 text-white font-weight-bold ls-m slide-animate" id="sense" #target>
              <h1 style="font-size: 72px;" class="d-none d-lg-block d-md-block d-sm-block display-1 notranslate">Blue Rebel interior</h1>
              <h2 style="font-size: 36px; font-weight: normal;" class="d-none d-lg-block d-md-block d-sm-block display-1">Trigger your senses</h2>
              <h1 style="font-size: 36px;" class="d-block d-sm-none display-1 notranslate">Blue Rebel interior</h1>
              <h2 style="font-size: 25px; font-weight: normal;" class="d-block d-sm-none display-1">Trigger your senses</h2>
              <fa-icon (click)="scroll(target)" style="margin-bottom: 8%; color: rgb(140, 140, 140);" [icon]="faChevronDown"></fa-icon>
          </div>
          </div>
       </div>
    </div>
 </div>
 
 

   <div class="container-xl d-none d-lg-block d-md-block d-sm-block">
       <div class="jumbotron">
           <div class="container">
               <h1 class="display-4">{{block.sBlockName}}</h1>
               <div class="lead-block" style="font-size: 1.88rem !important;" [innerHTML]="block.sBlockContent"></div>
               <div class="row" style="margin-top: 30px;">
                   <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection/furniture']">
                       <div class="furniture" style="margin-right: 10px;">
                           <div class="text-center">
                               <img class="img-fluid" style="width: 100%;" src="assets/img/BR_Tekening_Furniture.png" />
                           </div>
                           <h1 class="block-title">Furniture</h1>
                       </div>
                   </div>
                   <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection/decorations']">
                       <div class="decorations" style="margin-right: 10px;">
                           <div class="text-center">
                               <img class="img-fluid" style="width: 100%;" src="assets/img/BR_Tekening_Decorations.png" />
                           </div>
                           <h1 class="block-title">Decorations</h1>
                       </div>
                   </div>
                   <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection/architecturals']">
                       <div class="archtitecturals" style="margin-right: 10px;">
                           <div class="text-center">
                               <img class="img-fluid" style="width: 100%;" src="assets/img/BR_Tekening_Architecturals.png" />
                           </div>
                           <h1 class="block-title">Architecturals</h1>
                       </div>
                   </div>
                   <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection/textiles']">
                       <div class="textiles">
                           <div class="text-center">
                               <img class="img-fluid" style="width: 100%;" src="assets/img/BR_Tekening_Textiles.png" />
                           </div>
                           <h1 class="block-title">Textiles</h1>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="jumbotron" style="padding-left: 0px; padding-right: 0px;">
           <div class="container-xl">
               <h1 class="display-4">{{block2.sBlockName}}</h1>
               <!-- <p class="lead" style="margin-bottom: 0;">{{block2.sBlockContent}}</p> -->
               <div class="lead-block" style="font-size: 1.88rem !important;" [innerHTML]="block2.sBlockContent"></div>
               <div class="row" data-toggle="modal" data-target="#exampleModal" style="background-color: #2f3e46;">
                <div class="col-lg-12 col-md" style="padding:2%">
                     <div class="embedsocial-hashtag" data-ref="2021e5d95a8d55c9c1b5a95125969ee1b69d1f81"></div>
                </div>
                 <!-- <div id="carousel-example" class="carousel slide" data-interval="false" *ngFor="let item of gallery">
                    <div class="carousel-inner row w-100 mx-auto" role="listbox" >
                        <ng-container *ngFor="let image of item.stGalleryLines.arrGalleryLines; first as isFirst">
                        <div *ngIf="isFirst" class="carousel-item col-12 col-sm-6 col-md-4 col-lg-3 active">
                            <img src="{{images}}{{image.sImageName}}" class="img-fluid mx-auto d-block" alt="img1" />
                        </div>
                        </ng-container>
                        <ng-container *ngFor="let image of item.stGalleryLines.arrGalleryLines; first as isFirst">
                            <div *ngIf="!isFirst"class="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                <img src="{{images}}{{image.sImageName}}" class="img-fluid mx-auto d-block" alt="img1" />
                            </div>
                        </ng-container>
                    </div>
                    <a class="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carousel-example" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div> -->
            </div>
           </div>
       </div>
       <div class="jumbotron" style="padding-left: 0px; padding-right: 0px;" *ngIf="block3">
           <div class="container-xl">
               <h1 class="display-4">{{block3.sBlockName}}</h1>
               <!-- <p class="lead" style="margin-bottom: 0;">Blue Rebel Interior has a great love and passion for craftmanship,</p>
               <p class="lead" style="margin-bottom: 0;">that reflect on all items in the wholesale showroom.</p> -->
               <div class="lead-block" style="font-size: 1.88rem !important;" [innerHTML]="block3.sBlockContent"></div>

           </div>
       </div>
       <!-- <div class="row" style="margin-right: 0px;margin-left:0px;">
 <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection-detail']">
    <div class="furniture">
       <div class="text-center">
          <img class="img-fluid" style="width:100%;margin-top: 20%;" src="assets/img/BR_Tekening_Furniture.png">
       </div>
       <h1 class="block-title">Furniture</h1>
    </div>
 </div>
 <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection-detail2']">
    <div class="decorations">
       <div class="text-center">
          <img class="img-fluid" style="width:100%;margin-top: 20%;" src="assets/img/BR_Tekening_Decorations.png">
       </div>
       <h1 class="block-title">Decorations</h1>
    </div>
 </div>
 <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection-detail3']">
    <div class="archtitecturals">
       <div class="text-center">
          <img class="img-fluid" style="width:100%;margin-top: 20%;" src="assets/img/BR_Tekening_Architecturals.png">
       </div>
       <h1 class="block-title">Archtitecturals</h1>
    </div>
 </div>
 <div class="col-md-6 col-lg-3" id="block" [routerLink]="['/pages/collection-detail4']">
    <div class="textiles">
       <div class="text-center">
          <img class="img-fluid" style="width:100%;margin-top: 20%;" src="assets/img/BR_Tekening_Textiles.png">
       </div>
       <h1 class="block-title">Textiles</h1>
    </div>
 </div>
</div> -->
       <div class="row d-block d-sm-none">
           <div class="text-center" id="sense" style="padding-top: 50%;">
               <h1 class="display-1 block-title-mobile" style="font-size: 46px; color: #aaa091;">Collection</h1>
               <fa-icon [icon]="faChevronDown" style="color: rgb(140, 140, 140);"></fa-icon>
           </div>
           <div class="row collection">
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection/furniture']">
                   <img src="assets/img/icoontje_coll_mob_furniture.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px;">Furniture</p>
               </div>
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection-detail']">
                   <img src="assets/img/icoontje_coll_mob_decorations.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px;">Decorations</p>
               </div>
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection-detail']">
                   <img src="assets/img/icoontje_coll_mob_architecturals.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px;">Archtitecturals</p>
               </div>
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection-detail']">
                   <img src="assets/img/icoontje_coll_mob_textiles.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px;">Textiles</p>
               </div>
           </div>
       </div>
   </div>
   <div class="container-xl d-block d-sm-none">
       <!-- <div class="text-center">
           ​
           <picture>
               <source srcset="assets/img/eye.png" type="image/svg+xml" />
               <img style="margin-top: 200px;" src="assets/img/BR_oog.png" class="img-fluid eye" alt="..." />
           </picture>
       </div>
       <div class="text-center" id="sense">
           <h1 style="font-size: 72px;" class="d-none d-lg-block d-md-block d-sm-block display-1">Blue Rebel interior</h1>
           <h2 style="font-size: 36px; font-weight: normal;" class="d-none d-lg-block d-md-block d-sm-block display-1">Trigger your senses</h2>
           <h1 style="font-size: 46px;" class="d-block d-sm-none display-1">Blue Rebel interior</h1>
           <h2 style="font-size: 25px; font-weight: normal;" class="d-block d-sm-none display-1">Trigger your senses</h2>
           <fa-icon style="margin-bottom: 8%; color: rgb(140, 140, 140);" [icon]="faChevronDown"></fa-icon>
       </div> -->

       <div class="row d-block d-sm-none">
           <div class="jumbotron"  *ngIf="block">
               <div class="container">
                   <h1 class="display-4" id="mobile-h1">{{block.sBlockName}}</h1>
                   <div class="lead-block" [innerHTML]="block.sBlockContent"></div>
                   <!-- <p class="lead" style="margin-bottom: 0;" id="mobile-text">It’s all in the detail for Blue Rebel Interior. Blue Rebel Interior is a wholesaler in furniture, decorative items and unique pieces from all over the world.</p> -->
               </div>
           </div>
           <div class="row collection">
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection/furniture']">
                   <img src="assets/img/icoontje_coll_mob_furniture.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px; color: white;">Furniture</p>
               </div>
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection/decorations']">
                   <img src="assets/img/icoontje_coll_mob_decorations.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px; color: white;">Decorations</p>
               </div>
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection/architecturals']">
                   <img src="assets/img/icoontje_coll_mob_architecturals.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px; color: white;">Archtitecturals</p>
               </div>
               <div class="col-3" id="collection-icon" style="padding-right: 8px; padding-left: 8px;" [routerLink]="['/pages/collection/textiles']">
                   <img src="assets/img/icoontje_coll_mob_textiles.png" class="collection-icon img-thumbnail" alt="..." />
                   <p class="text-center" style="font-family: 'RobotoBold'; font-size: 15px; color: white;">Textiles</p>
               </div>
           </div>
           <div class="jumbotron">
               <div class="container">
                   <h1 class="display-4" id="mobile-h1">{{block2.sBlockName}}</h1>
                   <!-- <p class="lead" style="margin-bottom: 0;" id="mobile-text">Trigger your senses and be inspired.</p> -->
                   <div class="lead-block" [innerHTML]="block2.sBlockContent"></div>
               </div>
           </div>
       </div>
       <div class="row" style="background-color: #2f3e46; padding: 20%;">
        <div class="embedsocial-hashtag" data-ref="2021e5d95a8d55c9c1b5a95125969ee1b69d1f81"></div>
         <!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" style="width: 100%;">
                  <div class="carousel-item active">
                     <img class="d-block w-100" src="assets/img/IMG_5172.jpg" alt="Third slide" />
                  </div>

                  <div class="carousel-item">
                     <img class="d-block w-100" src="assets/img/IMG_5172.jpg" alt="Third slide" />
                  </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
            </a>
         </div> -->
       </div>
       <div class="jumbotron">
         <div class="container">
             <h1 class="display-4" id="mobile-h1">{{block3.sBlockName}}</h1>
             <!-- <p class="lead" style="margin-bottom: 0;" id="mobile-text">Blue Rebel Interior has a great love and passion for craftmanship, that reflect on all items
               in the wholesale showroom.</p> -->
               <div class="lead-block" [innerHTML]="block3.sBlockContent"></div>
         </div>
     </div>
   </div>
</div>
