import { Injectable, ChangeDetectorRef, Injector } from '@angular/core';
// import { Storage } from '@ionic/storage';
import { Item } from '../services/item';
import { Observable, of, throwError } from 'rxjs';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { AuthService } from '.././services/auth.service';
// import { BasePage } from '../pages/base-page/base-page';
// import { restoreView } from '@angular/core/src/render3';

const CART_KEY = 'cartItems';
const PBMCART_KEY = 'PBMcartItems';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  currentprice: number = 0;
  navbarCartCount = 0;
  navbarPBMCartCount = 0;
  OrderNumber: any;
  PBMOrderNumber: any;
  order: any;
  ideal:any;
  orderchange: any;
  constructor(injector: Injector, private commonhttp: HttpClient, private auth: AuthService) {
    this.calculateLocalCartProdCounts();
    this.calculateLocalPBMCartProdCounts();

  }
  //   addToCart(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       if (!this.containsObject(product, result)) {
  //         result.push(product);
  //         return this.storage.set(CART_KEY, result);
  //       } else {
  //         let index = result.findIndex(x => x.product_id == product.product_id);
  //         let prevQuantity = parseInt(result[index].count);
  //         product.count = (prevQuantity + product.count);
  //         let currentPrice = (parseFloat(product.totalPrice));
  //         product.totalPrice =currentPrice;
  //         this.currentprice = currentPrice
  //          result.splice(index, 1);
  //         result.push(product);
  //         return this.storage.set(CART_KEY, result);
  //       }

  //     } else {
  //       return this.storage.set(CART_KEY, [product]);
  //     }
  //   })
  // }

  // Adding new Product to cart db if logged in else localStorage
  addToCart(data: any): void {
    let a: Item[];

    a = JSON.parse(localStorage.getItem('raadsma_cart'+`${window.localStorage.getItem('OrderNumber')}`)) || [];

    a.push(data);
    // this.toastrService.wait('Adding Product to Cart', 'Product Adding to the cart');
    setTimeout(() => {
      localStorage.setItem('raadsma_cart'+`${window.localStorage.getItem('OrderNumber')}`, JSON.stringify(a));
      this.calculateLocalCartProdCounts();
    }, 500);
  }

  // returning LocalCarts Product Count
  calculateLocalCartProdCounts() {
    this.navbarCartCount = this.getLocalCartProducts().length;
  }

  getLocalCartProducts(): Item[] {
    const products: Item[] = JSON.parse(localStorage.getItem('raadsma_cart'+`${window.localStorage.getItem('OrderNumber')}`)) || [];

    return products;
  }

  removeLocalCartProduct(product: Item) {
    const products: Item[] = JSON.parse(localStorage.getItem('raadsma_cart'+`${window.localStorage.getItem('OrderNumber')}`));

    for (let i = 0; i < products.length; i++) {
      if (products[i].sArticleCode === product.sArticleCode) {
        products.splice(i, 1);
        break;
      }
    }
    // ReAdding the products after remove
    localStorage.setItem('raadsma_cart'+`${window.localStorage.getItem('OrderNumber')}`, JSON.stringify(products));

    this.calculateLocalCartProdCounts();
  }

  removeorderlocal(){
    localStorage.removeItem('Redson'+`${window.localStorage.getItem('OrderNumber')}`);
    this.calculateLocalCartProdCounts();
  }

  // removeFromCart(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.set(CART_KEY, result);
  //     }
  //   })
  // }

  // postOrder(): Observable<any> {
  //   let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem('Authtoken'));;
  //   headers.append('Access-Control-Allow-Origin' , '*');
  //   headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //   headers.append('Accept','application/json');
  //   headers.append('content-type','application/json');
  //   headers.append('content-type','application/json');
  //   console.log('UserToken', window.localStorage.getItem('Authtoken'))
  //   let sale = this.http.post('/Order', {headers:headers});
  //   console.log('order', sale);
  //   return sale;
  // }

  // postOrder() {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Order';
  //     let user = this.http.post(url, '', options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async postOrderNative(): Promise<HTTPResponse> {

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   console.log('post order native token', window.localStorage.getItem('Authtoken'))
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/Order', {}, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed.stAuthResp.iPbmMode);
  //         console.log('carserviceordernumber', parsed.sResponse)
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  // }

  postOrder(): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'UserToken': `${window.localStorage.getItem("Authtoken")}`
    }
    console.log('postorder', headers)
    return this.commonhttp.post('https://bri.msgrestservices.nl/Order', '', {'headers':headers})
  }


  deleteOrderLine(item, orderlineid): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'UserToken': `${window.localStorage.getItem("Authtoken")}`
    }
    return this.commonhttp.put('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid, item,{'headers':headers})
  }

  // deleteOrderLineNative(item, orderlineid): Promise<HTTPResponse> {

  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(item)
  //   console.log(orderlineid)
  //   console.log('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid)
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid, item, headers)
  //   .then(
  //     res =>  {
  //       console.log('remove item', res.status);
  //       if(res.status === 204 || res.status === 401){

  //       }else{
  //         let parsed = JSON.parse(res.data);
  //         console.log('carserviceordernumber', parsed['sResponse '])
  //       }
  //     },
  //     error => error);
  
  //     return sale
      
  // }

  // postOrderListName(name) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Orderlist?' + name;
  //     let user = this.http.post(url, name, options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           // this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async postOrderListNameNative(name): Promise<HTTPResponse> {

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/Orderlist', name, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         // this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed.stAuthResp.iPbmMode);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  // }

  // postOrderListItem(id, name) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Orderlist/' + id;
  //     let user = this.http.post(url, name, options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async postOrderListItemNative(id, name): Promise<HTTPResponse> {

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/Orderlist/' + id, name, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         // this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed.stAuthResp.iPbmMode);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  // }


  postOrderNativeNumber(data) {
    this.storeOrdernumber(data['sResponse']);
  }

  // postPBMOrder() {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Order';
  //     let user = this.http.post(url, '', options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storePBMOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async postPBMOrderNative(): Promise<HTTPResponse> {

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/Order', {}, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         this.storePBMOrdernumber(parsed.sResponse);
  //         console.log(parsed.stAuthResp.iPbmMode);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  // }

  // postPBMOrderListName(name) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem("PBMtoken"))
  //     let url = '/Orderlist?' + name;
  //     let user = this.http.post(url, name, options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           // this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async postPBMOrderListNameNative(name): Promise<HTTPResponse> {

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/Orderlist', name, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         // this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed.stAuthResp.iPbmMode);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  // }

  // postPBMOrderListItem(id, name) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem("PBMtoken"))
  //     let url = '/Orderlist/' + id;
  //     let user = this.http.post(url, name, options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storePBMOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async postPBMOrderListItemNative(id, name): Promise<HTTPResponse> {

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/Orderlist/' + id, name, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed.stAuthResp.iPbmMode);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  // }


  postPBMOrderNativeNumber(data) {
    let parsed = JSON.parse(data.data);
    this.storePBMOrdernumber(parsed.sResponse);
  }

  // getOrder(){
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Order/'+window.localStorage.getItem('OrderNumber'); 
  //     console.log(url)
  //     let user = this.http.get(url,options)
  //     .subscribe( data => {
  //       console.log(data.json())
  //       this.order = data.json();
  //         if(data.json().sResponse){
  //             console.log('carserviceordernumber', data.json().sResponse)
  //             this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //             resolve(false);
  //     });
  //   });
  // }

  // async getOrderNative() {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   this.nativeHttp.setDataSerializer("json");
  //   this.nativeHttp.get('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('OrderNumber'), {}, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       this.storeOrdernumber(parsed.sResponse);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  getOrder(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem('Authtoken'));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', window.localStorage.getItem('Authtoken'))
    // headers.append('UserToken', window.localStorage.getItem('Authtoken'));
    let items = this.commonhttp.get('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), { headers: headers });
    console.log('order', items);
    return items;
  }

  // getOrderNative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   console.log(headers)
  //   this.nativeHttp.setDataSerializer("json");
  //   console.log(headers);
  //   let items = this.nativeHttp.get('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), {}, headers);
  //   console.log('order', items);
  //   console.log('this is native');
  //   return items;
  // }

  getOrderLine(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem('Authtoken'));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', window.localStorage.getItem('Authtoken'))
    // headers.append('UserToken', window.localStorage.getItem('Authtoken'));
    let items = this.commonhttp.get('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem('OrderNumber'), { headers: headers });
    console.log('orderlines', items);
    return items;
  }

  // getOrderLineNative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   console.log(headers)
  //   this.nativeHttp.setDataSerializer("json");
  //   console.log(headers);
  //   let items = this.nativeHttp.get('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber'), {}, headers);
  //   return items
  // }

  getPBMOrder(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("PBMtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', window.localStorage.getItem("PBMtoken"))
    // headers.append('UserToken', window.localStorage.getItem('Authtoken'));
    let items = this.commonhttp.get('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), { headers: headers });
    console.log('order', items);
    return items;
  }

  // getPBMOrderNative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   console.log(headers)
  //   this.nativeHttp.setDataSerializer("json");
  //   console.log(headers);
  //   let items = this.nativeHttp.get('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('PBMOrderNumber'), {}, headers);
  //   console.log('order', items);
  //   console.log('this is native');
  //   return items;
  // }

  getPBMOrderLine(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("PBMtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', window.localStorage.getItem("PBMtoken"))
    // headers.append('UserToken', window.localStorage.getItem('Authtoken'));
    let items = this.commonhttp.get('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem('PBMOrderNumber'), { headers: headers });
    console.log('orderlines', items);
    return items;
  }

  // getPBMOrderLineNative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   console.log(headers)
  //   this.nativeHttp.setDataSerializer("json");
  //   console.log(headers);
  //   let items = this.nativeHttp.get('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem('PBMOrderNumber'), {}, headers);
  //   console.log('orderlines', items);
  //   console.log('this is native');
  //   return items;
  // }

  // putOrder(orderlines, stAddrDelivery, stAddrInvoice, form) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "test",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         // "stAddrDelivery": {
  //         //   stAddrDelivery
  //         // },
  //         // "stAddrInvoice": {
  //         //   stAddrDelivery
  //         // },
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": [

  //         ]
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": 24.9,
  //         "curTotGross": 24.9,
  //         "curTotIncl": 30.13,
  //         "curTotVAT": 5.23,
  //         "iNbOfLines": 1,
  //         "iTotPoints": 0,
  //         "arrOrderLine": [
  //           orderlines
  //         ]
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": "https://testraadsma2018.msgnl.com/raadsma_web/nl/PaymentCompleteReturn.awp?OrderNr=" + `${window.localStorage.getItem('OrderNumber')}` + "%26Project=DYK"
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 21,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log(json);
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Order/' + window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // putOrderNative(orderlines, stAddrDelivery, form): Promise<HTTPResponse> {

  //   // return new Promise((resolve, reject) => {
  //   let json = {
  //     "stOrder": {
  //       "bComplete": false,
  //       "bInform": false,
  //       "bUseNews": false,
  //       "bUrgentOrder": false,
  //       "iAccClient": 0,
  //       "sAccAdres": "",
  //       "sContactMail": form.value.email,
  //       "sContactTel": form.value.telephone,
  //       "sContactGsm": form.value.mobile,
  //       "sOrderCompTyp": "",
  //       "sOrderCorr": "",
  //       "sOrderKvkNr": "",
  //       "sOrderMemo": form.value.notice,
  //       "sOrderName": form.value.name,
  //       "sOrderSexe": "1",
  //       "sOrderUser": "test",
  //       "sOrderValuta": "EUR",
  //       "sOrderVatNr": "",
  //       "sOrderWork": "",
  //       "sOrderYourRef": form.value.reference,
  //       "sOrderXML": "",
  //       "stAddrDelivery": {
  //         stAddrDelivery
  //       },
  //       "stAddrInvoice": {
  //         stAddrDelivery
  //       },
  //       "stAddrWork": {
  //         "iAddressNr": 0,
  //         "sAddressName": "",
  //         "sAddressType": "WRK",
  //         "sCity": "",
  //         "sCountry": "",
  //         "sCountryCode": "",
  //         "sHouseNumber": "",
  //         "sSociete": "",
  //         "sStreet1": "",
  //         "sStreet2": "",
  //         "sZipCode": "",
  //         "sLongitude": "",
  //         "sLatitude": ""
  //       }
  //     },
  //     "stOrderFiles": {
  //       "arrArtURL": [

  //       ]
  //     },
  //     "stOrderLines": {
  //       "curTotExcl": 24.9,
  //       "curTotGross": 24.9,
  //       "curTotIncl": 30.13,
  //       "curTotVAT": 5.23,
  //       "iNbOfLines": 1,
  //       "iTotPoints": 0,
  //       "arrOrderLine": [
  //         orderlines
  //       ]
  //     },
  //     "stOrderPayment": {
  //       "sAccBic": "",
  //       "sAccCity": "",
  //       "sAccIban": "",
  //       "sAccName": "",
  //       "sAccNr": "",
  //       "sCardType": "",
  //       "sCardOwner": "",
  //       "sCardNr": "",
  //       "sCardSafety": "",
  //       "sCardMonth": "",
  //       "sCardYear": "",
  //       "sIdealStat": "",
  //       "sIdealParam": "",
  //       "sIdealError": "",
  //       "sIdealPayID": "",
  //       "sIdealPM": "",
  //       "sIdealLink": "https://testraadsma2018.msgnl.com/raadsma_web/nl/PaymentCompleteReturn.awp?OrderNr=" + `${window.localStorage.getItem('OrderNumber')}` + "%26Project=DYK"
  //     },
  //     "stOrderState": {
  //       "bEMaintenance": false,
  //       "bKopAccJN": false,
  //       "bKopOCI": false,
  //       "dDeliveryDate": "0000-00-00",
  //       "dtOrderStamp": "0000-00-00T00:00:00.000",
  //       "iDeliveryType": 1,
  //       "iOrderLang": 3,
  //       "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //       "iOrderState": 7,
  //       "iOrderType": 100,
  //       "iOrderPayment": 21,
  //       "sOrderIPAddr": "::1",
  //       "sOrderValuta": "EUR"
  //     }
  //   }
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };

  //   console.log(window.localStorage.getItem('Authtoken'))
  //   // this.nativeHttp.put('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('OrderNumber'), json, headers)
  //   //   .then(
  //   //     res => resolve(res),
  //   //     error => reject(<any>error));

  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  //   // });

  // }
  // putFactOrder(orderlines, stAddrDelivery, form) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     console.log(stAddrDelivery)
  //     console.log('form data', form)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "test",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         "stAddrDelivery": {
  //           stAddrDelivery
  //         },
  //         "stAddrInvoice": {
  //           stAddrDelivery
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": [

  //         ]
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": 24.9,
  //         "curTotGross": 24.9,
  //         "curTotIncl": 30.13,
  //         "curTotVAT": 5.23,
  //         "iNbOfLines": 1,
  //         "iTotPoints": 0,
  //         "arrOrderLine": [
  //           orderlines
  //         ]
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log(json);
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     let url = '/Order/' + window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // putFactOrderNative(orderlines, stAddrDelivery, form): Promise<HTTPResponse> {

  //   // return new Promise((resolve, reject) => {
  //   let json = {
  //     "stOrder": {
  //       "bComplete": false,
  //       "bInform": false,
  //       "bUseNews": false,
  //       "bUrgentOrder": false,
  //       "iAccClient": 0,
  //       "sAccAdres": "",
  //       "sContactMail": form.value.email,
  //       "sContactTel": form.value.telephone,
  //       "sContactGsm": form.value.mobile,
  //       "sOrderCompTyp": "",
  //       "sOrderCorr": "",
  //       "sOrderKvkNr": "",
  //       "sOrderMemo": form.value.notice,
  //       "sOrderName": form.value.name,
  //       "sOrderSexe": "1",
  //       "sOrderUser": "test",
  //       "sOrderValuta": "EUR",
  //       "sOrderVatNr": "",
  //       "sOrderWork": "",
  //       "sOrderYourRef": form.value.reference,
  //       "sOrderXML": "",
  //       "stAddrDelivery": {
  //         stAddrDelivery
  //       },
  //       "stAddrInvoice": {
  //         stAddrDelivery
  //       },
  //       "stAddrWork": {
  //         "iAddressNr": 0,
  //         "sAddressName": "",
  //         "sAddressType": "WRK",
  //         "sCity": "",
  //         "sCountry": "",
  //         "sCountryCode": "",
  //         "sHouseNumber": "",
  //         "sSociete": "",
  //         "sStreet1": "",
  //         "sStreet2": "",
  //         "sZipCode": "",
  //         "sLongitude": "",
  //         "sLatitude": ""
  //       }
  //     },
  //     "stOrderFiles": {
  //       "arrArtURL": [

  //       ]
  //     },
  //     "stOrderLines": {
  //       "curTotExcl": 24.9,
  //       "curTotGross": 24.9,
  //       "curTotIncl": 30.13,
  //       "curTotVAT": 5.23,
  //       "iNbOfLines": 1,
  //       "iTotPoints": 0,
  //       "arrOrderLine": [
  //         orderlines
  //       ]
  //     },
  //     "stOrderPayment": {
  //       "sAccBic": "",
  //       "sAccCity": "",
  //       "sAccIban": "",
  //       "sAccName": "",
  //       "sAccNr": "",
  //       "sCardType": "",
  //       "sCardOwner": "",
  //       "sCardNr": "",
  //       "sCardSafety": "",
  //       "sCardMonth": "",
  //       "sCardYear": "",
  //       "sIdealStat": "",
  //       "sIdealParam": "",
  //       "sIdealError": "",
  //       "sIdealPayID": "",
  //       "sIdealPM": "",
  //       "sIdealLink": ""
  //     },
  //     "stOrderState": {
  //       "bEMaintenance": false,
  //       "bKopAccJN": false,
  //       "bKopOCI": false,
  //       "dDeliveryDate": "0000-00-00",
  //       "dtOrderStamp": "0000-00-00T00:00:00.000",
  //       "iDeliveryType": 1,
  //       "iOrderLang": 3,
  //       "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //       "iOrderState": 7,
  //       "iOrderType": 100,
  //       "iOrderPayment": 1,
  //       "sOrderIPAddr": "::1",
  //       "sOrderValuta": "EUR"
  //     }
  //   }
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };

  //   console.log(window.localStorage.getItem('Authtoken'))
  //   // this.nativeHttp.put('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('OrderNumber'), json, headers)
  //   //   .then(
  //   //     res => resolve(res),
  //   //     error => reject(<any>error));

  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  //   // });

  // }


  // putPBMOrder(orderlines, stAddrDelivery, form) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "test",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         "stAddrDelivery": {
  //           stAddrDelivery
  //         },
  //         "stAddrInvoice": {
  //           stAddrDelivery
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": [

  //         ]
  //       },
  //       "stOrderLines": {
  //         orderlines
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log(json);
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem("PBMtoken"))
  //     let url = '/Order/' + window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // putPBMOrderNative(orderlines, stAddrDelivery, form): Promise<HTTPResponse> {

  //   // return new Promise((resolve, reject) => {
  //   let json = {
  //     "stOrder": {
  //       "bComplete": false,
  //       "bInform": false,
  //       "bUseNews": false,
  //       "bUrgentOrder": false,
  //       "iAccClient": 0,
  //       "sAccAdres": "",
  //       "sContactMail": form.value.email,
  //       "sContactTel": form.value.telephone,
  //       "sContactGsm": form.value.mobile,
  //       "sOrderCompTyp": "",
  //       "sOrderCorr": "Raadsma APP",
  //       "sOrderKvkNr": "",
  //       "sOrderMemo": form.value.notice,
  //       "sOrderName": form.value.name,
  //       "sOrderSexe": "1",
  //       "sOrderUser": "test",
  //       "sOrderValuta": "EUR",
  //       "sOrderVatNr": "",
  //       "sOrderWork": "",
  //       "sOrderYourRef": form.value.reference,
  //       "sOrderXML": "",
  //       "stAddrDelivery": {
  //         stAddrDelivery
  //       },
  //       "stAddrInvoice": {
  //         stAddrDelivery
  //       },
  //       "stAddrWork": {
  //         "iAddressNr": 0,
  //         "sAddressName": "",
  //         "sAddressType": "WRK",
  //         "sCity": "",
  //         "sCountry": "",
  //         "sCountryCode": "",
  //         "sHouseNumber": "",
  //         "sSociete": "",
  //         "sStreet1": "",
  //         "sStreet2": "",
  //         "sZipCode": "",
  //         "sLongitude": "",
  //         "sLatitude": ""
  //       }
  //     },
  //     "stOrderFiles": {
  //       "arrArtURL": [

  //       ]
  //     },
  //     "stOrderLines": {
  //       orderlines
  //     },
  //     "stOrderPayment": {
  //       "sAccBic": "",
  //       "sAccCity": "",
  //       "sAccIban": "",
  //       "sAccName": "",
  //       "sAccNr": "",
  //       "sCardType": "",
  //       "sCardOwner": "",
  //       "sCardNr": "",
  //       "sCardSafety": "",
  //       "sCardMonth": "",
  //       "sCardYear": "",
  //       "sIdealStat": "",
  //       "sIdealParam": "",
  //       "sIdealError": "",
  //       "sIdealPayID": "",
  //       "sIdealPM": "",
  //       "sIdealLink": ""
  //     },
  //     "stOrderState": {
  //       "bEMaintenance": false,
  //       "bKopAccJN": false,
  //       "bKopOCI": false,
  //       "dDeliveryDate": "0000-00-00",
  //       "dtOrderStamp": "0000-00-00T00:00:00.000",
  //       "iDeliveryType": 1,
  //       "iOrderLang": 3,
  //       "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //       "iOrderState": 7,
  //       "iOrderType": 100,
  //       "iOrderPayment": 1,
  //       "sOrderIPAddr": "::1",
  //       "sOrderValuta": "EUR"
  //     }
  //   }
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };

  //   console.log(window.localStorage.getItem("PBMtoken"))
  //   // this.nativeHttp.put('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('OrderNumber'), json, headers)
  //   //   .then(
  //   //     res => resolve(res),
  //   //     error => reject(<any>error));

  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale
  //   // });

  // }

  // putOrderMollieNewFactNative(orderlines, stAddrDelivery, stAddrInvoice, form, formfact, email, date, deliverytype): Promise<HTTPResponse> {
  //   let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         "stAddrInvoice": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "1",
  //           "sCity": formfact.value.city,
  //           "sCountry": "Nederland",
  //           "sCountryCode": "NL",
  //           "sHouseNumber": formfact.value.housenumber,
  //           "sLatitude": "",
  //           "sLongitude": "",
  //           "sSociete": "",
  //           "sStateCode": "",
  //           "sStateName": "",
  //           "sStreet1": formfact.value.street+''+formfact.value.housenumber,
  //           "sStreet2": "",
  //           "sZipCode": formfact.value.zipcode
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       // stOrderLines,
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": date,
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": deliverytype,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 21,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(window.localStorage.getItem("Authtoken"))
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         this.ideal = parsed.stOrderPayment.sIdealPayURL;
  //         console.log('idealurl', this.ideal)
  //       },
  //       error => error);
  //   return sale

  // }

  // putOrderFactNewFactNative(orderlines, stAddrDelivery, stAddrInvoice, form, formfact, email, date, deliverytype): Promise<HTTPResponse> {
  //   let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         "stAddrInvoice": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "1",
  //           "sCity": formfact.value.city,
  //           "sCountry": "Nederland",
  //           "sCountryCode": "NL",
  //           "sHouseNumber": formfact.value.housenumber,
  //           "sLatitude": "",
  //           "sLongitude": "",
  //           "sSociete": "",
  //           "sStateCode": "",
  //           "sStateName": "",
  //           "sStreet1": formfact.value.street+''+formfact.value.housenumber,
  //           "sStreet2": "",
  //           "sZipCode": formfact.value.zipcode
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       // stOrderLines,
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": date,
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": deliverytype,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(window.localStorage.getItem("Authtoken"))
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         this.ideal = parsed.stOrderPayment.sIdealPayURL;
  //         console.log('idealurl', this.ideal)
  //       },
  //       error => error);
  //   return sale

  // }

  // putPBMOrderFactNewFactNative(orderlines, stAddrDelivery, stAddrInvoice, form, formfact, email, pbmuser): Promise<HTTPResponse> {
  //   let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": pbmuser.sContactMail,
  //         "sContactTel": pbmuser.sContactTel,
  //         "sContactGsm": pbmuser.sContactGsm,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": pbmuser.sOrderCorr,
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": "",
  //         "sOrderName": pbmuser.sOrderName,
  //         "sOrderSexe": pbmuser.sOrderSexe,
  //         "sOrderUser": pbmuser.sOrderUser,
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": "",
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         "stAddrInvoice": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "1",
  //           "sCity": formfact.value.city,
  //           "sCountry": "Nederland",
  //           "sCountryCode": "NL",
  //           "sHouseNumber": formfact.value.housenumber,
  //           "sLatitude": "",
  //           "sLongitude": "",
  //           "sSociete": "",
  //           "sStateCode": "",
  //           "sStateName": "",
  //           "sStreet1": formfact.value.street+''+formfact.value.housenumber,
  //           "sStreet2": "",
  //           "sZipCode": formfact.value.zipcode
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       // stOrderLines,
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('PBMOrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 2,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   console.log(window.localStorage.getItem("PBMtoken"))
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('PBMOrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         this.ideal = parsed.stOrderPayment.sIdealPayURL;
  //         console.log('idealurl', this.ideal)
  //       },
  //       error => error);
  //   return sale

  // }

  // putOrderMollieNative(orderlines, stAddrDelivery, stAddrInvoice, form, email, date, deliverytype): Promise<HTTPResponse> {
  //   let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": date,
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": deliverytype,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 21,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(window.localStorage.getItem("Authtoken"))
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         this.ideal = parsed.stOrderPayment.sIdealPayURL;
  //         console.log('idealurl', this.ideal)
  //       },
  //       error => error);
  //   return sale

  // }

  // putPBMOrderFactNative(orderlines, stAddrDelivery, stAddrInvoice, form, email, pbmuser): Promise<HTTPResponse> {
  //   let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": pbmuser.sContactMail,
  //         "sContactTel": pbmuser.sContactTel,
  //         "sContactGsm": pbmuser.sContactGsm,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": pbmuser.sOrderCorr,
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": "",
  //         "sOrderName": pbmuser.sOrderName,
  //         "sOrderSexe": pbmuser.sOrderSexe,
  //         "sOrderUser": pbmuser.sOrderUser,
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": "",
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('PBMOrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 2,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   console.log(window.localStorage.getItem("PBMtoken"))
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('PBMOrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         this.ideal = parsed.stOrderPayment.sIdealPayURL;
  //         console.log('idealurl', this.ideal)
  //       },
  //       error => error);
  //   return sale

  // }

  // putOrderFactNative(orderlines, stAddrDelivery, stAddrInvoice, form, email, date, deliverytype): Promise<HTTPResponse> {
  //   let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     console.log('delivertype process',deliverytype)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": date,
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": deliverytype,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //   console.log(json);
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(window.localStorage.getItem("Authtoken"))
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/Order/'+window.localStorage.getItem('OrderNumber'), json, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         console.log(parsed);
  //         this.ideal = parsed.stOrderPayment.sIdealPayURL;
  //         console.log('idealurl', this.ideal)
  //       },
  //       error => error);
  //   return sale

  // }

  // putOrderMollieNewFact(orderlines, stAddrDelivery, stAddrInvoice, form, formfact, email) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     console.log('putorderfunction1')
  //     let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         "stAddrInvoice": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "1",
  //           "sCity": formfact.value.city,
  //           "sCountry": "Nederland",
  //           "sCountryCode": "NL",
  //           "sHouseNumber": formfact.value.housenumber,
  //           "sLatitude": "",
  //           "sLongitude": "",
  //           "sSociete": "",
  //           "sStateCode": "",
  //           "sStateName": "",
  //           "sStreet1": formfact.value.street+''+formfact.value.housenumber,
  //           "sStreet2": "",
  //           "sZipCode": formfact.value.zipcode
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 21,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("Authtoken"));
  //     console.log(window.localStorage.getItem("Authtoken"))
  //     let url = '/Order/'+window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().stOrderPayment) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           //this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //           this.ideal = data.json().stOrderPayment.sIdealPayURL;
  //           console.log('idealurl', this.ideal)
  //         }
  //         else
  //           resolve(false);
  //       });
  //       console.log(user)
  //   });
  // }


  // putPBMOrderFactNewFact(orderlines, stAddrDelivery, stAddrInvoice, form, formfact, email, pbmuser) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     console.log('putorderfunction1')
  //     let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": pbmuser.sContactMail,
  //         "sContactTel": pbmuser.sContactTel,
  //         "sContactGsm": pbmuser.sContactGsm,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": pbmuser.sOrderCorr,
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": "",
  //         "sOrderName": pbmuser.sOrderName,
  //         "sOrderSexe": pbmuser.sOrderSexe,
  //         "sOrderUser": pbmuser.sOrderUser,
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": "",
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         "stAddrInvoice": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "1",
  //           "sCity": formfact.value.city,
  //           "sCountry": "Nederland",
  //           "sCountryCode": "NL",
  //           "sHouseNumber": formfact.value.housenumber,
  //           "sLatitude": "",
  //           "sLongitude": "",
  //           "sSociete": "",
  //           "sStateCode": "",
  //           "sStateName": "",
  //           "sStreet1": formfact.value.street+''+formfact.value.housenumber,
  //           "sStreet2": "",
  //           "sZipCode": formfact.value.zipcode
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('PBMOrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 2,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem("PBMtoken"))
  //     let url = '/Order/'+window.localStorage.getItem('PBMOrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().stOrderPayment) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           //this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //           this.ideal = data.json().stOrderPayment.sIdealPayURL;
  //           console.log('idealurl', this.ideal)
  //         }
  //         else
  //           resolve(false);
  //       });
  //       console.log(user)
  //   });
  // }

  // putOrderFactNewFact(orderlines, stAddrDelivery, stAddrInvoice, form, formfact, email) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     console.log('putorderfunction1')
  //     let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "Van DIJK",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         "stAddrInvoice": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "1",
  //           "sCity": formfact.value.city,
  //           "sCountry": "Nederland",
  //           "sCountryCode": "NL",
  //           "sHouseNumber": formfact.value.housenumber,
  //           "sLatitude": "",
  //           "sLongitude": "",
  //           "sSociete": "",
  //           "sStateCode": "",
  //           "sStateName": "",
  //           "sStreet1": formfact.value.street+''+formfact.value.housenumber,
  //           "sStreet2": "",
  //           "sZipCode": formfact.value.zipcode
  //         },
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', json);
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("Authtoken"));
  //     console.log(window.localStorage.getItem("Authtoken"))
  //     let url = '/Order/'+window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().stOrderPayment) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           //this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //           this.ideal = data.json().stOrderPayment.sIdealPayURL;
  //           console.log('idealurl', this.ideal)
  //         }
  //         else
  //           resolve(false);
  //       });
  //       console.log(user)
  //   });
  // }

  // putOrderMollie(orderlines, stAddrDelivery, stAddrInvoice, form, email) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     console.log('putorderfunction1')
  //     let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "Van DIJK",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 21,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', JSON.stringify(json));
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("Authtoken"));
  //     console.log(window.localStorage.getItem("Authtoken"))
  //     let url = '/Order/'+window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().stOrderPayment) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           //this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //           this.ideal = data.json().stOrderPayment.sIdealPayURL;
  //           console.log('idealurl', this.ideal)
  //         }
  //         else
  //           resolve(false);
  //       });
  //       console.log(user)
  //   });
  // }

  // putOrderFact(orderlines, stAddrDelivery, stAddrInvoice, form, email) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('OrderNumber'))
  //     console.log('putorderfunction1')
  //     let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": form.value.email,
  //         "sContactTel": form.value.telephone,
  //         "sContactGsm": form.value.mobile,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": "Van DIJK",
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": form.value.notice,
  //         "sOrderName": form.value.name,
  //         "sOrderSexe": "1",
  //         "sOrderUser": "",
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": form.value.reference,
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('OrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 100,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', JSON.stringify(json));
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("Authtoken"));
  //     console.log(window.localStorage.getItem("Authtoken"))
  //     let url = '/Order/'+window.localStorage.getItem('OrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().stOrderPayment) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           //this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //           this.ideal = data.json().stOrderPayment.sIdealPayURL;
  //           console.log('idealurl', this.ideal)
  //         }
  //         else
  //           resolve(false);
  //       });
  //       console.log(user)
  //   });
  // }

  // putPBMOrderFact(orderlines, stAddrDelivery, stAddrInvoice, form, email, pbmuser) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     console.log(this.currentPath + '/thanks/' + window.localStorage.getItem('PBMOrderNumber'))
  //     console.log('putorderfunction1')
  //     let arrOrderLine = orderlines
  //     console.log(stAddrDelivery)
  //     let json = {
  //       "stOrder": {
  //         "bComplete": false,
  //         "bInform": false,
  //         "bUseNews": false,
  //         "bUrgentOrder": false,
  //         "iAccClient": 0,
  //         "sAccAdres": "",
  //         "sContactMail": pbmuser.sContactMail,
  //         "sContactTel": pbmuser.sContactTel,
  //         "sContactGsm": pbmuser.sContactGsm,
  //         "sOrderCompTyp": "",
  //         "sOrderCorr": pbmuser.sOrderCorr,
  //         "sOrderKvkNr": "",
  //         "sOrderMemo": "",
  //         "sOrderName": pbmuser.sOrderName,
  //         "sOrderSexe": pbmuser.sOrderSexe,
  //         "sOrderUser": pbmuser.sOrderUser,
  //         "sOrderValuta": "EUR",
  //         "sOrderVatNr": "",
  //         "sOrderWork": "",
  //         "sOrderYourRef": "",
  //         "sOrderXML": "",
  //         stAddrDelivery,
  //         stAddrInvoice,
  //         "stAddrWork": {
  //           "iAddressNr": 0,
  //           "sAddressName": "",
  //           "sAddressType": "WRK",
  //           "sCity": "",
  //           "sCountry": "",
  //           "sCountryCode": "",
  //           "sHouseNumber": "",
  //           "sSociete": "",
  //           "sStreet1": "",
  //           "sStreet2": "",
  //           "sZipCode": "",
  //           "sLongitude": "",
  //           "sLatitude": ""
  //         }
  //       },
  //       "stOrderFiles": {
  //         "arrArtURL": []
  //       },
  //       "stOrderLines": {
  //         "curTotExcl": "",
  //         "curTotGross": "",
  //         "curTotIncl": "",
  //         "curTotVAT": "",
  //         "iNbOfLines": "",
  //         "iTotPoints": "",
  //         arrOrderLine
  //       },
  //       "stOrderPayment": {
  //         "sAccBic": "",
  //         "sAccCity": "",
  //         "sAccIban": "",
  //         "sAccName": "",
  //         "sAccNr": "",
  //         "sCardType": "",
  //         "sCardOwner": "",
  //         "sCardNr": "",
  //         "sCardSafety": "",
  //         "sCardMonth": "",
  //         "sCardYear": "",
  //         "sIdealStat": "",
  //         "sIdealParam": "",
  //         "sIdealError": "",
  //         "sIdealPayID": "",
  //         "sIdealPM": "",
  //         "sIdealLink": ""
  //       },
  //       "stOrderState": {
  //         "bEMaintenance": false,
  //         "bKopAccJN": false,
  //         "bKopOCI": false,
  //         "dDeliveryDate": "0000-00-00",
  //         "dtOrderStamp": "0000-00-00T00:00:00.000",
  //         "iDeliveryType": 1,
  //         "iOrderLang": 3,
  //         "iOrderNumber": window.localStorage.getItem('PBMOrderNumber'),
  //         "iOrderState": 7,
  //         "iOrderType": 2,
  //         "iOrderPayment": 1,
  //         "sOrderIPAddr": "::1",
  //         "sOrderValuta": "EUR"
  //       }
  //     }
  //     console.log('putorder', JSON.stringify(json));
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem("PBMtoken"))
  //     let url = '/Order/'+window.localStorage.getItem('PBMOrderNumber');
  //     console.log(url)
  //     console.log(this.orderchange)
  //     let user = this.http.put(url, json, options)
  //       .subscribe(data => {
  //         console.log(data.json())
  //         if (data.json().stOrderPayment) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           //this.storeOrdernumber(data.json().sResponse);
  //           this.OrderNumber = data.json().sResponse
  //           this.ideal = data.json().stOrderPayment.sIdealPayURL;
  //           console.log('idealurl', this.ideal)
  //         }
  //         else
  //           resolve(false);
  //       });
  //       console.log(user)
  //   });
  // }




  storeOrdernumber(OrderNumber) {
    window.localStorage.setItem('OrderNumber', OrderNumber);
    // this.storage.set('OrderNumber', OrderNumber);
    this.useOrdernumber(OrderNumber);
  }

  storePBMOrdernumber(OrderNumber) {
    window.localStorage.setItem('PBMOrderNumber', OrderNumber);
    // this.storage.set('PBMOrderNumber', OrderNumber);
    this.useOrdernumber(OrderNumber);
  }

  usePBMOrdernumber(token) {
    this.PBMOrderNumber = token;
  }

  useOrdernumber(token) {
    this.OrderNumber = token;
  }


  // postOrderLine(item: any) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     console.log('test', item);
  //     let url = '/OrderLine/' + window.localStorage.getItem('OrderNumber');
  //     let user = this.http.post(url, JSON.stringify(item), options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // deleteOrderLine(item: any, orderlineid) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("Authtoken"));
  //     console.log(window.localStorage.getItem("Authtoken"))
  //     console.log('test', item);
  //     let url = '/OrderLine/' + window.localStorage.getItem('OrderNumber')+'/'+orderlineid;
  //     console.log(url)
  //     let user = this.http.put(url, JSON.stringify(item), options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('deleteitem', data.json().sResponse)
  //           // //this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // deletePBMOrderLine(item: any, orderlineid) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem("PBMtoken"))
  //     console.log('test', item);
  //     let url = '/OrderLine/' + window.localStorage.getItem('PBMOrderNumber')+'/'+orderlineid;
  //     console.log(url)
  //     let user = this.http.put(url, JSON.stringify(item), options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('deleteitem', data.json().sResponse)
  //           // //this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // deleteOrderLineNative(item, orderlineid): Promise<HTTPResponse> {

  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(item)
  //   console.log(orderlineid)
  //   console.log('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid)
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid, item, headers)
  //   .then(
  //     res =>  {
  //       console.log('remove item', res.status);
  //       if(res.status === 204 || res.status === 401){

  //       }else{
  //         let parsed = JSON.parse(res.data);
  //         console.log('carserviceordernumber', parsed['sResponse '])
  //       }
  //       // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //       // this.storeUserID(parsed.stAuthResp.iUserId);
  //       // this.storeClientID(parsed.stAuthResp.iClient);
  //       // this.storeUserType(parsed.stAuthResp.iUserType);
  //       // this.storePBM(parsed.stAuthResp.iPbmMode);
  //       // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //       // console.log(parsed.stAuthResp.iPbmMode);
  //       // console.log('carserviceordernumber', parsed.sResponse)
  //       //this.storeOrdernumber(parsed.sResponse);
  //       // console.log(parsed);
  //       // this.userFullname(parsed.stAuthResp.sFullName);
  //     },
  //     error => error);
  
  //     return sale
      
  // }


  // OrderLineNative(item, orderlineid): Promise<HTTPResponse> {

  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(item)
  //   console.log(orderlineid)
  //   console.log('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid)
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber')+'/'+orderlineid, item, headers)
  //   .then(
  //     res =>  {let parsed = JSON.parse(res.data);
  //       // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //       // this.storeUserID(parsed.stAuthResp.iUserId);
  //       // this.storeClientID(parsed.stAuthResp.iClient);
  //       // this.storeUserType(parsed.stAuthResp.iUserType);
  //       // this.storePBM(parsed.stAuthResp.iPbmMode);
  //       // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //       // console.log(parsed.stAuthResp.iPbmMode);
  //       console.log('carserviceordernumber', parsed.sResponse)
  //       //this.storeOrdernumber(parsed.sResponse);
  //       console.log(parsed);
  //       // this.userFullname(parsed.stAuthResp.sFullName);
  //     },
  //     error => error);
  
  //     return sale
      
  // }

  // async deletePBMOrderLineNative(item, orderlineid): Promise<HTTPResponse> {
  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.put('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('PBMOrderNumber')+'/'+orderlineid, item, headers)
  //   .then(
  //     res =>  {let parsed = JSON.parse(res.data);
  //       // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //       // this.storeUserID(parsed.stAuthResp.iUserId);
  //       // this.storeClientID(parsed.stAuthResp.iClient);
  //       // this.storeUserType(parsed.stAuthResp.iUserType);
  //       // this.storePBM(parsed.stAuthResp.iPbmMode);
  //       // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //       // console.log(parsed.stAuthResp.iPbmMode);
  //       console.log('carserviceordernumber', parsed.sResponse)
  //       //this.storeOrdernumber(parsed.sResponse);
  //       console.log(parsed);
  //       // this.userFullname(parsed.stAuthResp.sFullName);
  //     },
  //     error => error);
  
  //     return sale
      
  // }

  // postPBMOrderLine(item: any) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem("PBMtoken"));
  //     console.log(window.localStorage.getItem('Authtoken'))
  //     console.log('test', item);
  //     let url = '/OrderLine/'+window.localStorage.getItem("PBMOrderNumber");
  //     let user = this.http.post(url, JSON.stringify(item), options)
  //       .subscribe(data => {
  //         if (data.json().sResponse) {
  //           console.log('carserviceordernumber', data.json().sResponse)
  //           this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // postOrderLineNative(item) {
  //   return new Promise((resolve, reject) => {
  //     let headers = {
  //       'Content-Type': 'application/json',
  //       "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //     };
  //     this.nativeHttp.post('https://bri.msgrestservices.nl/OrderLine/'+window.localStorage.getItem('OrderNumber'), item, headers)
  //       .then(
  //         res => resolve(res),
  //         error => reject(<any>error));
  //   });
  // }

  // async postOrderLineNative(item): Promise<HTTPResponse> {
  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem('OrderNumber'), item, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         // console.log(parsed.stAuthResp.iPbmMode);
  //         console.log('carserviceordernumber', parsed.sResponse)
  //         this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale

  // }

  // async postOrderLineNative(item): Observable<any> {
  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.commonhttp.post('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem('OrderNumber'), item, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         // console.log(parsed.stAuthResp.iPbmMode);
  //         console.log('carserviceordernumber', parsed.sResponse)
  //         this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale

  // }

  postOrderLine(item): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      "UserToken": `${window.localStorage.getItem("Authtoken")}`
    }
    const body=JSON.stringify(item);
    console.log(body)
    return this.commonhttp.post('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem('OrderNumber'), body,{'headers':headers})
  }

  putOrderFactNewFact(orderlines, stAddrInvoice, form, formfact, email, date, deliverytype): Observable<any> {
    let arrOrderLine = orderlines
    const headers = {
      'Content-Type': 'application/json',
      "UserToken": `${window.localStorage.getItem("Authtoken")}`
    }
    let json = {
      "stOrder": {
        "bComplete": false,
        "bInform": false,
        "bUseNews": false,
        "bUrgentOrder": false,
        "iAccClient": 0,
        "sAccAdres": "",
        "sContactMail": form.value.email,
        "sContactTel": form.value.telephone,
        "sContactGsm": form.value.mobile,
        "sOrderCompTyp": "",
        "sOrderCorr": "",
        "sOrderKvkNr": "",
        "sOrderMemo": form.value.notice,
        "sOrderName": form.value.name,
        "sOrderSexe": "1",
        "sOrderUser": "",
        "sOrderValuta": "EUR",
        "sOrderVatNr": "",
        "sOrderWork": "",
        "sOrderYourRef": form.value.reference,
        "sOrderXML": "",
        "stAddrDelivery":{
          "iAddressNr": 0,
          "sAddressName": "",
          "sAddressType": "1",
          "sCity": form.value.city,
          "sCountry": "Nederland",
          "sCountryCode": "NL",
          "sHouseNumber": form.value.housenumber,
          "sLatitude": "",
          "sLongitude": "",
          "sSociete": "",
          "sStateCode": "",
          "sStateName": "",
          "sStreet1": form.value.street+''+form.value.housenumber,
          "sStreet2": "",
          "sZipCode": form.value.zipcode
        },
        "stAddrInvoice": {
          "iAddressNr": 0,
          "sAddressName": "",
          "sAddressType": "1",
          "sCity": formfact.value.city,
          "sCountry": "Nederland",
          "sCountryCode": "NL",
          "sHouseNumber": formfact.value.housenumber,
          "sLatitude": "",
          "sLongitude": "",
          "sSociete": "",
          "sStateCode": "",
          "sStateName": "",
          "sStreet1": formfact.value.street+''+formfact.value.housenumber,
          "sStreet2": "",
          "sZipCode": formfact.value.zipcode
        },
        "stAddrWork": {
          "iAddressNr": 0,
          "sAddressName": "",
          "sAddressType": "WRK",
          "sCity": "",
          "sCountry": "",
          "sCountryCode": "",
          "sHouseNumber": "",
          "sSociete": "",
          "sStreet1": "",
          "sStreet2": "",
          "sZipCode": "",
          "sLongitude": "",
          "sLatitude": ""
        }
      },
      "stOrderFiles": {
        "arrArtURL": []
      },
      // stOrderLines,
      "stOrderLines": {
        "curTotExcl": "",
        "curTotGross": "",
        "curTotIncl": "",
        "curTotVAT": "",
        "iNbOfLines": "",
        "iTotPoints": "",
        arrOrderLine
      },
      "stOrderPayment": {
        "sAccBic": "",
        "sAccCity": "",
        "sAccIban": "",
        "sAccName": "",
        "sAccNr": "",
        "sCardType": "",
        "sCardOwner": "",
        "sCardNr": "",
        "sCardSafety": "",
        "sCardMonth": "",
        "sCardYear": "",
        "sIdealStat": "",
        "sIdealParam": "",
        "sIdealError": "",
        "sIdealPayID": "",
        "sIdealPM": "",
        "sIdealLink": ""
      },
      "stOrderState": {
        "bEMaintenance": false,
        "bKopAccJN": false,
        "bKopOCI": false,
        "dDeliveryDate": date,
        "dtOrderStamp": "0000-00-00T00:00:00.000",
        "iDeliveryType": deliverytype,
        "iOrderLang": 3,
        "iOrderNumber": window.localStorage.getItem('OrderNumber'),
        "iOrderState": 7,
        "iOrderType": 100,
        "iOrderPayment": 1,
        "sOrderIPAddr": "::1",
        "sOrderValuta": "EUR"
      }
    }
    console.log(json)
    return this.commonhttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json,{'headers':headers})
  }

  putOrderFact(orderlines, stAddrInvoice, form, email, date, deliverytype, stAddrDelivery): Observable<any> {
    let arrOrderLine = orderlines
    const headers = {
      'Content-Type': 'application/json',
      "UserToken": `${window.localStorage.getItem("Authtoken")}`
    }
    let json = {
      "stOrder": {
        "bComplete": false,
        "bInform": false,
        "bUseNews": false,
        "bUrgentOrder": false,
        "iAccClient": 0,
        "sAccAdres": "",
        "sContactMail": form.value.email,
        "sContactTel": form.value.telephone,
        "sContactGsm": form.value.mobile,
        "sOrderCompTyp": "",
        "sOrderCorr": "",
        "sOrderKvkNr": "",
        "sOrderMemo": form.value.notice,
        "sOrderName": form.value.name,
        "sOrderSexe": "1",
        "sOrderUser": "",
        "sOrderValuta": "EUR",
        "sOrderVatNr": "",
        "sOrderWork": "",
        "sOrderYourRef": form.value.reference,
        "sOrderXML": "",
        stAddrDelivery,
        stAddrInvoice,
        // "stAddrInvoice": {
        //  "iAddressNr": 0,
        //   "sAddressName": "",
        //   "sAddressType": "1",
        //   "sCity": form.value.city,
        //   "sCountry": "Nederland",
        //   "sCountryCode": "NL",
        //   "sHouseNumber": form.value.housenumber,
        //   "sLatitude": "",
        //   "sLongitude": "",
        //   "sSociete": "",
        //   "sStateCode": "",
        //   "sStateName": "",
        //   "sStreet1": form.value.street+''+form.value.housenumber,
        //   "sStreet2": "",
        //   "sZipCode": form.value.zipcode
        // },
        "stAddrWork": {
          "iAddressNr": 0,
          "sAddressName": "",
          "sAddressType": "WRK",
          "sCity": "",
          "sCountry": "",
          "sCountryCode": "",
          "sHouseNumber": "",
          "sSociete": "",
          "sStreet1": "",
          "sStreet2": "",
          "sZipCode": "",
          "sLongitude": "",
          "sLatitude": ""
        }
      },
      "stOrderFiles": {
        "arrArtURL": []
      },
      // stOrderLines,
      "stOrderLines": {
        "curTotExcl": "",
        "curTotGross": "",
        "curTotIncl": "",
        "curTotVAT": "",
        "iNbOfLines": "",
        "iTotPoints": "",
        arrOrderLine
      },
      "stOrderPayment": {
        "sAccBic": "",
        "sAccCity": "",
        "sAccIban": "",
        "sAccName": "",
        "sAccNr": "",
        "sCardType": "",
        "sCardOwner": "",
        "sCardNr": "",
        "sCardSafety": "",
        "sCardMonth": "",
        "sCardYear": "",
        "sIdealStat": "",
        "sIdealParam": "",
        "sIdealError": "",
        "sIdealPayID": "",
        "sIdealPM": "",
        "sIdealLink": ""
      },
      "stOrderState": {
        "bEMaintenance": false,
        "bKopAccJN": false,
        "bKopOCI": false,
        "dDeliveryDate": date,
        "dtOrderStamp": "0000-00-00T00:00:00.000",
        "iDeliveryType": deliverytype,
        "iOrderLang": 3,
        "iOrderNumber": window.localStorage.getItem('OrderNumber'),
        "iOrderState": 7,
        "iOrderType": 100,
        "iOrderPayment": 1,
        "sOrderIPAddr": "::1",
        "sOrderValuta": "EUR"
      }
    }
    console.log(json)
    return this.commonhttp.put('https://bri.msgrestservices.nl/Order/' + window.localStorage.getItem('OrderNumber'), json,{'headers':headers})
  }


  // async postPBmOrderLineNative(item): Promise<HTTPResponse> {
  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://bri.msgrestservices.nl/OrderLine/' + window.localStorage.getItem("PBMOrderNumber"), item, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  //         // this.storeUserID(parsed.stAuthResp.iUserId);
  //         // this.storeClientID(parsed.stAuthResp.iClient);
  //         // this.storeUserType(parsed.stAuthResp.iUserType);
  //         // this.storePBM(parsed.stAuthResp.iPbmMode);
  //         // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
  //         // console.log(parsed.stAuthResp.iPbmMode);
  //         console.log('carserviceordernumber', parsed.sResponse)
  //         this.storeOrdernumber(parsed.sResponse);
  //         console.log(parsed);
  //         // this.userFullname(parsed.stAuthResp.sFullName);
  //       },
  //       error => error);

  //   return sale

  // }


  // removeAllCartItems(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.remove(CART_KEY);
  //     }
  //   })
  // }


  // containsObject(obj, list): boolean {
  //   if (!list.length) {
  //     return false;
  //   }

  //   if (obj == null) {
  //     return false;
  //   }
  //   var i;
  //   for (i = 0; i < list.length; i++) {
  //     if (list[i].product_id == obj.product_id) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }



  // getCartItems() {
  //   return this.storage.get(CART_KEY);
  // }

  // PBMaddToCart(product) {
  //   return this.getPBMCartItems().then(result => {
  //     if (result) {
  //       if (!this.containsObject(product, result)) {
  //         result.push(product);
  //         return this.storage.set(PBMCART_KEY, result);
  //       } else {
  //         let index = result.findIndex(x => x.product_id == product.product_id);
  //         let prevQuantity = parseInt(result[index].count);
  //         product.count = (prevQuantity + product.count);
  //         let currentPrice = (parseInt(product.iNbPoints) * product.count);
  //         product.iNbPoints =currentPrice;
  //          result.splice(index, 1);
  //         result.push(product);
  //         return this.storage.set(PBMCART_KEY, result);
  //       }

  //     } else {
  //       return this.storage.set(PBMCART_KEY, [product]);
  //     }
  //   })
  // }

  PBMaddToCart(data: any): void {
    let a: Item[];

    a = JSON.parse(localStorage.getItem('raadsma_pbm_cart'+`${window.localStorage.getItem('PBMOrderNumber')}`)) || [];

    a.push(data);
    // this.toastrService.wait('Adding Product to Cart', 'Product Adding to the cart');
    setTimeout(() => {
      localStorage.setItem('raadsma_pbm_cart'+`${window.localStorage.getItem('PBMOrderNumber')}`, JSON.stringify(a));
      this.calculateLocalPBMCartProdCounts();
    }, 500);
  }

  // returning LocalCarts Product Count
  calculateLocalPBMCartProdCounts() {
    this.navbarPBMCartCount = this.getLocalPBMCartProducts().length;
  }

  getLocalPBMCartProducts(): Item[] {
    const products: Item[] = JSON.parse(localStorage.getItem('raadsma_pbm_cart'+`${window.localStorage.getItem('PBMOrderNumber')}`)) || [];

    return products;
  }

  removeLocalPBMCartProduct(product: Item) {
    const products: Item[] = JSON.parse(localStorage.getItem('raadsma_pbm_cart'+`${window.localStorage.getItem('PBMOrderNumber')}`));

    for (let i = 0; i < products.length; i++) {
      if (products[i].sArticleCode === product.product_id) {
        products.splice(i, 1);
        break;
      }
    }
    // ReAdding the products after remove
    localStorage.setItem('raadsma_pbm_cart'+`${window.localStorage.getItem('PBMOrderNumber')}`, JSON.stringify(products));

    this.calculateLocalPBMCartProdCounts();
  }

  // PBMremoveFromCart(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.set(PBMCART_KEY, result);
  //     }
  //   })
  // }

  // PBMremoveAllCartItems(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.remove(PBMCART_KEY);
  //     }
  //   })
  // }


  // PBMcontainsObject(obj, list): boolean {
  //   if (!list.length) {
  //     return false;
  //   }

  //   if (obj == null) {
  //     return false;
  //   }
  //   var i;
  //   for (i = 0; i < list.length; i++) {
  //     if (list[i].product_id == obj.product_sArticleCode) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }


  // getPBMCartItems() {
  //   return this.storage.get(PBMCART_KEY);
  // }
}
