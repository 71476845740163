

  <!-- <app-header-two *ngIf="url == '/home/two'  "></app-header-two>
  <app-header-three *ngIf="url == '/home/four' || url == '/home/four'"></app-header-three>
  <app-header-four *ngIf="url == '/home/five' || url == '/home/five'"></app-header-four> -->
  <router-outlet></router-outlet>

  <!-- <app-footer *ngIf="url != '/home/five'"></app-footer>
  <app-footer-two *ngIf="url == '/home/five'"></app-footer-two> -->

  <!--End -->


