import { Injectable } from '@angular/core';
import { OfflineManagerService } from './offline-manager.service';
import { Observable, of, throwError, from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
// import { Platform, ToastController, LoadingController } from '@ionic/angular';
import { NetworkService, ConnectionStatus } from './network.service';
import { forkJoin } from 'rxjs';
// import { Storage } from '@ionic/storage';

const API_STORAGE_KEY = 'specialkey';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, private networkService: NetworkService, private offlineManager: OfflineManagerService) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getCategories(): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
    //   // Return the cached data from Storage
    //   console.log('network', this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Online)
    //   return from(this.getLocalData('categories'));
    // } else {
      // const headers = {
      //   'Content-Type': 'application/json',
      //   'Access-Control-Allow-Origin': '*',
      //   'UserToken': `${window.localStorage.getItem("Authtoken")}`
      // }
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let categories = this.http.get('https://bri.msgrestservices.nl/Category', { headers: headers });
      console.log('category', categories);
      console.log('this is browser');
      return categories;
    // }
  }


  getSubCategories(category): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    let subcategoies = this.http.get('https://bri.msgrestservices.nl/Category?ParentId='+category+'&LimitLevel=4', {headers:headers});
    console.log('search', subcategoies);
    return subcategoies;
  }

  // private setLocalData(key, data) {
  //   this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  // }
 
  // // Get cached API result
  // private getLocalData(key) {
  //   return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  // }
}
