       <!-- Load Conent -->
       <div class="container-xl">
          <div class="card">
            <div class="card-body" *ngIf="orderstate === 9">
                <h2 class="bold">
                    Bedankt voor uw bestelling!
                  </h2>
                  <p>
                    Uw bestelling met Ordernummer: {{order}} is geplaatst en wordt momenteel verwerkt. U zal een email ontvangen met de details van de bestelling.
                
                  </p>
                
                  <button class="btn-primary btn-lg" (click)="goToHome()">
                    Maak nog een Bestelling
                  </button>
            </div>
            <div class="card-body" *ngIf="orderstate === 3">
                <h2 class="bold">
                    Helaas!
                  </h2>
                  <p>
                    Uw bestelling met Ordernummer: {{order}} is niet betaald.        
                  </p>
                
                  <button class="btn-primary btn-lg" (click)="goToHome()">
                    Maak nog een Bestelling
                  </button>
            </div>
            <div class="card-body" *ngIf="orderstate === 85">
                <h2 class="bold">
                    Helaas!
                  </h2>
                  <p>
                    Uw bestelling met Ordernummer: {{order}} is niet betaald.        
                  </p>
                
                  <button class="btn-primary btn-lg" (click)="goToHome()">
                    Maak nog een Bestelling
                  </button>
            </div>
          </div>
    </div>