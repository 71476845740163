
        

                <nav id="menu" class="navbar fixed-top navbar-expand-lg navbar-dark navbar-fixed-top">
                    <div class="logo" itemscope itemtype="https://schema.org/Organization">
                        <a href="/"itemprop="url" style="margin-left:10%" class="navbar-brand">
                            <picture>
                                <source type="image/svg+xml" srcset="assets/img/BLUE_REBEL logo.png">
                                <img itemprop="logo" class="img-responsive" alt="Redson" height="90" width="190" src="assets/img/BLUE_REBEL logo.png">
                            </picture>
                        </a>
                    </div>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                        <span style="color:white;"class="navbar-toggler-icon"></span>
                    </button>
                    <div style="margin-right:4%" class="navbar-collapse collapse" id="navbarsExample05">
                        <ul itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement" class="navbar-nav ml-auto">
                            <!-- <ng-container *ngFor="let item of basicmenu">
                                <ng-container *ngFor="let menu of item.stMenuItems.arrMenuItem">
                                    <ng-container *ngIf="menu.stSubPages.arrMenuItem.length === 0">
                                        <li class="nav-item">
                                            <a class="nav-link" (click)="goToPageID(menu)"><span style="font-size:25px;">{{menu.sPageName}}</span></a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="menu.stSubPages.arrMenuItem.length > 0">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" (click)="goToPageID(menu)" id="navbarDropdown" aria-haspopup="true" aria-expanded="false">
                                                <span style="font-size:25px;">{{menu.sPageName}} </span>
                                            </a>
                            
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a class="dropdown-item" *ngFor="let block of menu.stSubPages.arrMenuItem" href="#">{{block.sPageName}}</a>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ng-container> -->
                            <li itemprop="name" class="nav-item">
                                <a itemprop="url" class="nav-link" href="/"><span style="font-size:25px;">Home</span> <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link" href="/#/pages/collection" id="navbarDropdown" aria-haspopup="true" aria-expanded="false">
                                    <span style="font-size:25px;">Collection</span>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                  <a class="dropdown-item" href="/#/pages/collection/furniture">Furniture</a>
                                  <a class="dropdown-item" href="/#/pages/collection/decorations">Decorations</a>
                                  <a class="dropdown-item" href="/#/pages/collection/architecturals">Architecturals</a>
                                  <a class="dropdown-item" href="/#/pages/collection/textiles">Textiles</a>
                                </div>
                              </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:void(0);" (click)="GoToGallery()"><span style="font-size:25px;">Gallery</span></a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="/#/pages/about"><span style="font-size:25px;">About us</span></a>
                            </li>
                            <li class="nav-item contact">
                                <a class="nav-link" href="/#/pages/contact"><span style="font-size:25px;">Contact</span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
                
                