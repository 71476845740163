import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../../services/content.service';
import { NavigationExtras,Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  basicmenu:any;
  topmenu:any;
  footercol1:any;
  footercol2:any;
  footercol3:any;
  footercol4:any;
  navbar:any;
  routerLinkActive="active"
  constructor(public contentService: ContentService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    this.getBasicMenu();
  }
  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

  goToPageID(item) {
    const url = this.router.url.split('?')[0];
    let navigationExtras: NavigationExtras = {
      state: {
        slug:item['sSlug'],
        id:item['iPgPageID'],
      }
    };
    console.log(navigationExtras.state)
    this.router.navigate(['/pages/' + item['sSlug']]);
  }

  async getBasicMenu() {
    this.contentService.getMenu()
    .subscribe(res => {
      let menu = res['stCmsMenu']['arrMenu'];
      this.basicmenu = menu.filter(i => i.iMenuType === 1);
      console.log('menu',this.basicmenu)
    }, err => {
      console.log(err);
      // loading.dismiss();
    });
  }

  GoToGallery(){
    this.router.navigate(['pages/gallery'])
  .then(() => {
    window.location.reload();
  });
  }

}
