import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  constructor(private http: HttpClient) { }

  // getSocial(): Observable<any> {
  //   let headers = new HttpHeaders();
  //   headers.append('Access-Control-Allow-Origin', '*');
  //   headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //   headers.append('Accept', 'application/json');
  //   headers.append('content-type', 'application/json');
  //   let promo = this.http.get('https://www.instagram.com/bluerebel.interior/?__a=1', { headers: headers });
  //   console.log('search', promo);
  //   return promo;
  // }
}
