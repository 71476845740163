import { Component, OnInit } from '@angular/core';
import { SocialService } from '../../../services/social.service';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faMapMarkerAlt = faMapMarkerAlt;
  constructor(private productService:SocialService) { }

  ngOnInit() {
    // this.loadSocial();
  }

  // async loadSocial() {
  //   this.productService.getSocial()
  //     .subscribe(res => {
  //       // console.log('products', res);
  //       console.log(res)
  //       // if(res){
  //       // this.items = res['stArticles']['arrArticleInList'];
  //       // this.group = res['stGroupInfo'];
  //       // this.spinner.hide();
  //       // }
  //       // this.filters = res['stPropFilter']['arrProp'];
  //       // loading.dismiss();
  //     }, err => {
  //       console.log(err);
  //       // loading.dismiss();
  //     });

  // }

}
