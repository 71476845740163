<div class="container-xl  product-detail">
    <table class="table" *ngFor="let item of items">
        <thead>
        <tr>
            <th scope="col">Artikelnummer</th>
            <th scope="col">Artikelomschrijving</th>
            <th *ngIf="authService.checkPriceTypeNettoPriceIncl() || authService.checkPriceTypeNettoPriceExcl() || authService.checkPriceTypeGrossPrice()" scope="col">Prijs per {{item.sPricePer}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of item.stVariants.stVariantArticles.arrVariantsArticles" (click)="Go(row.sArticleCode);$event.stopPropagation()"  >
            <th scope="row">{{row.sArticleCode}}</th>
            <td>{{row.sDescription}}</td>
            <td *ngIf="authService.checkPriceTypeNettoPriceIncl()">{{row.curNetPriceInc | currency:'EUR':number:'':'nl':true}}</td>
            <td *ngIf="authService.checkPriceTypeNettoPriceExcl()">{{row.curNetPrice | currency:'EUR':number:'':'nl':true}}</td>
            <td *ngIf="authService.checkPriceTypeGrossPrice()">{{row.curNetPrice | currency:'EUR':number:'':'nl':true}}</td>
        </tr>
        </tbody>
    </table>
</div>