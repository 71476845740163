import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../services/product.service';
import { Product } from 'src/app/modals/product.model';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ContentService } from '../../../services/content.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  products: Product[];
  public banners = [];
  gallery:any
  block:any;
  block2:any;
  block3:any;
  images = 'https://msgcms.msgsoftware.nl/MsgCms_web/BRI/ShopImages/'
  faChevronDown = faChevronDown;
  constructor(private productService: ProductService,public contentService: ContentService) { }

  ngOnInit() {
    this.productService.getBanner()
    .subscribe(
      data => this.banners = data
    );
    let home_discover_our_collection = 'home_discover_our_collection'
    let home_gallery_block = 'home_gallery_block'
    let home_personal_touch = 'home_personal_touch'
    this.getGallery();
    this.getBlock(home_discover_our_collection)
    this.getBlock2(home_gallery_block)
    this.getBlock3(home_personal_touch)
//  this.productService.getProducts()
//  .subscribe(
//    (product: Product[]) => {
//      this.products = product
//    }
//  )

  }

  async getGallery() {
    this.contentService.getGallery()
    .subscribe(res => {
      this.gallery = res['stCmsGallery']['arrGallery'];
      // this.gallery = menu.filter(i => i.sGalleryTitle === "Main");
      console.log('gallery',this.gallery)
    }, err => {
      console.log(err);
      // loading.dismiss();
    });
  }

  async getBlock(item) {
    this.contentService.getBlock(item)
    .subscribe(res => {
      this.block = res['stCmsBlock'];
      // this.gallery = menu.filter(i => i.sGalleryTitle === "Main");
      console.log('gallery',this.block)
    }, err => {
      console.log(err);
      // loading.dismiss();
    });
  }

  async getBlock2(item) {
    this.contentService.getBlock(item)
    .subscribe(res => {
      this.block2 = res['stCmsBlock'];
      // this.gallery = menu.filter(i => i.sGalleryTitle === "Main");
      console.log('gallery',this.block2)
    }, err => {
      console.log(err);
      // loading.dismiss();
    });
  }

  async getBlock3(item) {
    this.contentService.getBlock(item)
    .subscribe(res => {
      this.block3 = res['stCmsBlock'];
      // this.gallery = menu.filter(i => i.sGalleryTitle === "Main");
      console.log('gallery',this.block3)
    }, err => {
      console.log(err);
      // loading.dismiss();
    });
  }




  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
}

}
