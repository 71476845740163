<div class="container-xl  product-detail">

   <nav aria-label="breadcrumb">
       <ol class="breadcrumb">
           <li class="breadcrumb-item"><a href="#">Home</a></li>
           <li class="breadcrumb-item"><a href="#">Webshop</a></li>
           <li class="breadcrumb-item"><a href="#">{{breadcrumb}}</a></li>
           <li class="breadcrumb-item active" aria-current="page">{{ item?.sDescription1 }} </li>
       </ol>
   </nav>

   <div class="row">

       <div class="col-md-4">

        <ngb-carousel interval=0 id="productImages">
            <div  class="carousel slide" *ngFor="let photo of item?.stImages.arrArtImg">
                <ng-template  ngbSlide>
                    <div class="carousel-inner">
                        <img class="carousel-item active" [src]="shopimagesmedium + photo.sImageName"  alt="Random first slide">
                    </div>
                </ng-template>
            </div>
       </ngb-carousel>

        
           <!-- <div class="carousel slide" id="productImages" data-ride="carousel">
               <ol class="carousel-indicators">
                   <li data-target="#productImages" *ngFor="let photo of item?.stImages.arrArtImg; let i = index" style="background-image: url('{{shopimagesmedium + photo.sImageName}}');width:50px;height: 50px;background-size: contain;background-position: center center;background-repeat: no-repeat;" onerror="this.src='../assets/img/default-img.png';" attr.data-slide-to="{{i}}" class="border"></li>

               </ol>
               <div class="carousel-inner">
                <img class="carousel-item active" *ngFor="let photo of item?.stImages.arrArtImg"  src="{{shopimagesmedium + photo.sImageName}}" onerror="this.src='../assets/img/default-img.png';">

          
              </div>
               <a class="carousel-control-prev" href="#productImages" role="button" data-slide="prev">
                   <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                   <span class="sr-only">Previous</span>
               </a>
               <a class="carousel-control-next" href="#productImages" role="button" data-slide="next">
                   <span class="carousel-control-next-icon" aria-hidden="true"></span>
                   <span class="sr-only">Next</span>
               </a>
           </div> -->

       </div>

       <div class="col-md-8">

           <div class="card-header bg-transparent border-0 p-0 pt-2 text-dark">
               <h1 class="card-title font-weight-light">{{ item?.sDescription1 }}</h1>
               <p *ngIf="authService.checkPriceTypeNettoPriceIncl()" class="card-text font-weight-bold text-redson">{{ item?.stPrice.curNetPriceInc | currency:'EUR':number:'':'nl':true}} <small class="text-dark">INCL. BTW</small></p>
               <p *ngIf="authService.checkPriceTypeNettoPriceExcl()" class="card-text font-weight-bold text-redson">{{ item?.stPrice.curNetPrice | currency:'EUR':number:'':'nl':true}} <small class="text-dark">EXCL. BTW</small></p>
               <p *ngIf="authService.checkPriceTypeGrossPrice()" class="card-text font-weight-bold text-redson">{{ item?.stPrice.curGrossPrice | currency:'EUR':number:'':'nl':true}} <small class="text-dark">Bruto Prijs</small></p>
            </div>
           <div class="card-body d-flex align-items-center text-center">

           </div>
           <div class="card-footer bg-transparent border-0 text-center effect-top">

           </div>
           <form *ngIf="authService.checkPriceTypeNettoPriceIncl() || authService.checkPriceTypeNettoPriceExcl() || authService.checkPriceTypeGrossPrice()">
               <div class="input-group mb-1">
                    <div class="input-group-append">
                        <a (click)="losesMinFocus($event,item)" role="button" class="btn btn-secondary btn-lg" style="color:white;">-</a>
                    </div>
                   <input style="text-align: center;" id="mySelect_2331" (keyup.enter)="onEnter(item)" class="form-control form-control-lg border-secondary select" min="{{item?.nQuantityMin}}" (blur)="focus($event, item)" step="{{item.nQuantityStep}}" type="number" (change)="losesFocus($event, item)" name="customer" value="{{item.nQuantity}}">
                   <div class="input-group-append">
                       <a (click)="losesFocus($event,item)" role="button" class="btn btn-secondary btn-lg" style="color:white;">+</a>
                   </div>
               </div>
               <div class="input-group mb-1">
                <div class="input-group">
                    <a (click)="addToCart(item)" role="button" class="btn btn-secondary btn-lg btn-block" style="color:white;">Voeg Toe</a>
                </div>
               </div>
           </form>
       </div>

   </div>

   <div class="row">
       <div class="col-12">
           <ul class="nav nav-tabs" id="myTab" role="tablist">
               <li class="nav-item">
                   <a class="nav-link active" id="home-tab" data-toggle="tab" href="#gegevens" role="tab" aria-controls="home" aria-selected="true">Gegevens</a>
               </li>
               <li class="nav-item">
                   <a class="nav-link" id="profile-tab" data-toggle="tab" href="#meerinformatie" role="tab" aria-controls="profile" aria-selected="false">Meer informatie</a>
               </li>
               <li class="nav-item" *ngIf="items?.length">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#related" role="tab" aria-controls="profile" aria-selected="false">Gerelateerde Artikelen</a>
            </li>
           </ul>
           <div class="tab-content" id="myTabContent">
               <div class="tab-pane fade show active pt-5" id="gegevens" role="tabpanel" aria-labelledby="gegevens-tab">
                   <h2>{{ item?.sDescription1 }}</h2>
                   <p><strong>{{ item?.sDescription2 }}</strong></p>
                   <p><strong>Beschrijving</strong></p>
                   <ul>
                       <li [innerHTML]="item?.sDescriptionLong"></li>
                   </ul>
                   <p><strong>Kenmerken</strong></p>
                   <ul>
                        <ng-container *ngFor="let item of properties">
                       <li>{{item?.sPropDesc}}: {{item?.sPropValue}} {{item?.sPropUnit}}</li>
                       </ng-container>
                   </ul>
                   <!-- <p><strong>Leveringsomvang</strong></p>
                   <ul>
                       <li>2x Accu GBA 36V 6.0Ah</li>
                       <li>1x Oplader</li>
                       <li>1x Diepteaanslag</li>
                       <li>1x Handgreep</li>
                       <li>1x Wisselhouder SDS-plus</li>
                       <li>1x Snelspanboorhouder</li>
                       <li>1x L-Boxx</li>
                       <li>1x GBH 36 VF-LI PLUS Boorhamer</li>
                   </ul>
                   <div>
                       <div><strong>Merkinformatie</strong></div>
                       <div>
                           <p>Bosch Blauw is een onderdeel van Bosch.&nbsp;<a title="Bosch Blauw" href="/gereedschap-merken/bosch-blauw.html" target="_blank">Bosch Blauw</a>&nbsp;produceert elektrische gereedschappen met de bekende kwaliteit van&nbsp;Bosch.&nbsp;Bosch Blauw produceert vrijwel alle soorten professioneel en semi-professioneel elektrisch gereedschap. Denk daarbij aan&nbsp;Accuboormachines,&nbsp;zaagmachines,&nbsp;freesmachines, maar ook aan&nbsp;afstandmeters&nbsp;en&nbsp;lijnlasers.In 1886 werd Bosch opgericht door Robert Bosch, destijds onder de naam "Werkstätte für Feinmechanik und Elektrotechnik". Sindsdien is het bedrijf gegroeit tot de multinational die het nu is.</p>
                           <p>Bosch produceert huishoudelijke toestellen, aandrijf en besturingssystemen, auto elektronica en elektrische gereedschappen.&nbsp;Bosch Blauw levert ook veel&nbsp;<a title="accesoires" href="/accessoires/zaagbladen.html?manufacturer=42" target="_blank">accessoires</a>&nbsp;zoals&nbsp;boren&nbsp;en&nbsp;zaagbladen. Gereedschappelijk kan u deze&nbsp;accessoires&nbsp;op aanvraag leveren.&nbsp;De elektrische apparaten van Bosch hebben natuurlijk elektrische energie nodig. In de gevallen dat deze niet door netstroom geleverd kan worden zijn we afhankelijk van&nbsp;accu's. Bosch levert dan ook veel van zijn elektrische gereedschappen met stroomvoorziening door&nbsp;<a title="Accu's" href="/accu-gereedschap/accu-en-acculaders/bosch-blauw-accus-en-acculaders.html" target="_blank">accu's</a>.&nbsp;Gereedschappelijk kan u alle benodige accu's en acculader apparatuur binnen 48 uur leveren.</p>
                       </div>
                   </div> -->


               </div>
               <div class="tab-pane fade  pt-5" id="meerinformatie" role="tabpanel" aria-labelledby="meer-informatie-tab">
                   <table class="data table additional-attributes" id="product-attribute-specs-table">
                       <tbody>
                           <tr>
                               <th class="col label" scope="row">ArtikelCode</th>
                               <td class="col data" data-th="EAN Code">{{item?.sArticleCode}}</td>
                           </tr>
                           <tr>
                               <th class="col label" scope="row">Merk</th>
                               <td class="col data" data-th="SKU">{{item?.sArticleBrand}}</td>
                           </tr>
                           <tr>
                            <th class="col label" scope="row">GTIN Code</th>
                            <td class="col data" data-th="SKU">{{item?.sGTIN}}</td>
                        </tr>
                       </tbody>
                   </table>
               </div>
               <div *ngIf="items?.length" class="tab-pane fade  pt-5" id="related" role="tabpanel" aria-labelledby="related-tab">
                <table class="table" *ngFor="let item of items">
                    <thead>
                    <tr>
                        <th scope="col">Artikelnummer</th>
                        <th scope="col">Artikelomschrijving</th>
                        <th *ngIf="authService.checkPriceTypeNettoPriceIncl() || authService.checkPriceTypeNettoPriceExcl() || authService.checkPriceTypeGrossPrice()" scope="col">Prijs per {{item.sPricePer}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr (click)="Go(item.sArticleCode);$event.stopPropagation()"  >
                        <th scope="row">{{item.sArticleCode}}</th>
                        <td>{{item.sDescription1}}</td>
                        <td *ngIf="authService.checkPriceTypeNettoPriceIncl()">{{item.curNetPriceInc | currency:'EUR':number:'':'nl':true}}</td>
                        <td *ngIf="authService.checkPriceTypeNettoPriceExcl()">{{item.curNetPrice | currency:'EUR':number:'':'nl':true}}</td>
                        <td *ngIf="authService.checkPriceTypeGrossPrice()">{{item.curNetPrice | currency:'EUR':number:'':'nl':true}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
           </div>
       </div>
   </div>



</div>
<!--End -->
    <!-- <div class="py-2 mt-2">
       <h2>Related Products</h2>
       <div class="divider"></div>
       <app-products-carousel [products]="relatedProducts"></app-products-carousel>
       </div> -->


